import { useMutation } from "@tanstack/react-query";
import { downloadReceipt } from "api";
import { saveAs } from "file-saver";
import { ReceiptResponse } from "types/api/api";

// eslint-disable-next-line
const useDownloadReceipt = (ptn: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isLoading, error, mutate } = useMutation(["receipt", ptn], () => downloadReceipt(ptn), {
    onSuccess: ({ data, filename }: ReceiptResponse) => {
      if (data && data.type !== "app/test") {
        saveAs(data, filename || "payment-proof.pdf");
      }
    }
  });

  return { mutate, isLoading, error };
};

export default useDownloadReceipt;

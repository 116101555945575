import { PRL_FLOW_TYPE } from "constants/flowTypes";
import { useAppContext } from "context/AppContext";
import useGetData from "hooks/api/useGetData";
import { useParams } from "react-router-dom";
import { TableOrderContentRowType } from "types/components/TableOrder";

const useGetYourOrderContent = (): TableOrderContentRowType[] => {
  const { type: flowType } = useParams();
  const { productData, prlData } = useGetData();
  const product = productData?.product || prlData?.product || [];

  const { productQuantity } = useAppContext();

  return product?.map((item) => {
    const quantity = flowType === PRL_FLOW_TYPE ? item?.quantity?.value : productQuantity;
    return {
      name: item?.name?.value,
      qty: quantity,
      price: Number(item?.price?.value),
      total: Number(item?.price?.value) * Number(quantity || 1)
    };
  });
};

export default useGetYourOrderContent;

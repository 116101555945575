const fetchHttpClient = (url: string, options: RequestInit, responseType = "json") => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const json = await response.json();
        const error = new Error(response.statusText);
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        (error as any).status = response.status;
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        (error as any).data = json;
        throw error;
      }
      if (responseType === "blob") {
        const blob = await response.blob();
        resolve({
          data: blob,
          filename: response.headers.get("Content-Disposition")?.split("=")[1]
        });
      } else {
        const json = await response.json();
        resolve({ data: json });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export default fetchHttpClient;

import ArrowIcon from "assets/icons/ArrowIcon";
import classnames from "classnames";
import Attachment from "components/base/Attachment/Attachment";
import { useAppContext } from "context/AppContext";
import useGetData from "hooks/api/useGetData";
import useGetQuote from "hooks/api/useGetQuote";
import useGetAccordionContent from "hooks/content/useGetAccordionContent";
import useGetYourOrderContent from "hooks/content/useGetYourOrderContent";
import useGetPrice from "hooks/utils/useGetPrice";
import { MouseEvent, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { DocumentObject, EnumCurrency } from "types/api/api";
import { AccordionHeadingType, AccordionItemType, AccordionPropsType } from "types/components/Accordion";
import formatCurrency from "utils/formatCurrency";
import ReturnkeyValue from "utils/returnKeyValue";
import styles from "./styles.module.scss";

interface ProductOrderProps {
  showHeader?: boolean;
  isLoading?: boolean;
  productQuantityWatcher?: number;
  inputComponent?: JSX.Element;
  quantityInputHandler?: JSX.Element;
  handleAttachmentClick?: (e: MouseEvent, file: DocumentObject) => void;
}

interface ProductAccordionItemProps extends AccordionItemType {
  currency?: EnumCurrency;
  expanded: boolean;
  headings: AccordionHeadingType[];
}

const Accordion = ({ expanded, items, headings, ...rest }: AccordionPropsType) => {
  return (
    <div data-testid="accordion" className={styles.accordion}>
      {items.map((itemProps, index) => (
        <AccordionItem
          expanded={expanded || false}
          key={index}
          headings={headings}
          {...rest}
          {...itemProps}
        />
      ))}
    </div>
  );
};

const AccordionItem = ({
  cardContent,
  currency,
  expansionContent,
  headings,
  expanded,
  ...rest
}: ProductAccordionItemProps) => {
  return (
    <div
      {...rest}
      data-testid="accordion-item"
      className={classnames(styles.accordionItemWrapper, {
        [styles.accordionItemWrapperExpanded]: expanded
      })}
    >
      <div className={styles.accordionItemExpansion}>
        {expansionContent.map(({ title, content }, index) => (
          <div
            className={styles.accordionItemExpansionContentWrapper}
            key={`${title}_${index}`}
            data-testid="accordion-expansion-item"
          >
            {title && <div className={styles.accordionItemExpansionTitle}>{String(title)}</div>}
            <div className={styles.accordionItemExpansionContent}>{content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ProductOrder = ({
  isLoading,
  inputComponent,
  quantityInputHandler,
  productQuantityWatcher,
  handleAttachmentClick,
  showHeader = true
}: ProductOrderProps) => {
  const { subtotalPrice, totalPrice } = useGetPrice();
  const { productData, themes } = useGetData();
  const rows = useGetYourOrderContent();
  const { quote } = useGetQuote();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const items = useGetAccordionContent({
    inputComponent: inputComponent || <span></span>,
    productQuantityWatcher: productQuantityWatcher || 0,
    renderAttachmentComponent: (attachments: DocumentObject[]) => (
      <Attachment items={attachments} onClick={handleAttachmentClick ? handleAttachmentClick : () => true} />
    )
  });

  const appContext = useAppContext();

  const headings = [
    {
      title: t("yourOrder.product"),
      xs: 3
    },
    {
      title: t("yourOrder.quantity"),
      xs: 2
    },
    {
      title: t("yourOrder.price"),
      xs: 3
    },
    {
      title: t("yourOrder.priceTotal"),
      xs: 4
    }
  ];

  const footerRows = [
    {
      title: t("yourOrder.subTotal"),
      value: formatCurrency(subtotalPrice)
    },
    {
      title: t("yourOrder.fees"),
      value: formatCurrency(quote || 0),
      highlight: true
    },
    {
      title: t("yourOrder.priceTotal"),
      value: formatCurrency(totalPrice)
    }
  ];

  const content = {
    items,
    headings,
    expanded
  };

  const convertToNumber = (value: string) => {
    return Number(String(value).trim().replace("FCFA", "").replace(/\s/g, ""));
  };

  return (
    <div>
      {showHeader && (
        <div
          data-testid="order-container-card"
          className="d-flex justify-content-between p-2 rounded mt-4 mb-0"
          style={{ border: `1px solid ${themes.headerColor}` }}
        >
          <Col>
            {headings?.map((heading, index) => (
              <Col
                key={index}
                xs={heading.xs}
                className={`${index === headings.length - 1 && "text-bold"} mb-1 w-100`}
                data-testid="table-order-content-heading-col"
              >
                <span className={styles.label}>{heading.title}</span>
              </Col>
            ))}
          </Col>

          <Col className={`${styles.product_details_value}`}>
            <div className="d-flex flex-column align-items-end">
              {ReturnkeyValue(rows[0])?.map((row, index) => {
                const value: string = row?.value as string;
                return (
                  <Col key={index} data-testid="table-order-content-row">
                    {row?.key === "qty" && quantityInputHandler ? (
                      quantityInputHandler
                    ) : row?.key === "total" ? (
                      <Col
                        xs={headings[index]?.xs}
                        className={`${index === headings.length - 1 && "text-bold"} w-100`}
                        data-testid="table-order-content-col"
                        key={value}
                      >
                        <span>
                          {/* {convertToNumber(value)} */}
                          {quantityInputHandler
                            ? formatCurrency(
                                Number(productQuantityWatcher || convertToNumber(value)) *
                                  (productData?.amount?.value || 0)
                              ) || value
                            : formatCurrency(
                                convertToNumber(String(rows[0]["price"])) *
                                  (appContext.productQuantity || Number(rows[0]["qty"]))
                              )}
                        </span>
                      </Col>
                    ) : row?.key === "price" ? (
                      <Col
                        xs={headings[index]?.xs}
                        className={`${index === headings.length - 1 && "text-bold"} w-100`}
                        data-testid="table-order-content-col"
                        key={value}
                      >
                        <span>{formatCurrency(Number(value))}</span>
                      </Col>
                    ) : (
                      <Col
                        xs={headings[index]?.xs}
                        className={`${index === headings.length - 1 && "text-bold"} w-100`}
                        data-testid="table-order-content-col"
                        key={value}
                      >
                        <span>
                          {value} {!value && row?.key === "qty" && appContext?.productQuantity}
                        </span>
                      </Col>
                    )}
                  </Col>
                );
              })}
            </div>
            {quantityInputHandler && (
              <div
                className={styles.accordionItemArrowWrapper}
                onClick={() => setExpanded(!expanded)}
                data-testid="accordion-item_arrow-icon-wrapper"
              >
                <ArrowIcon className={styles.accordionItemArrowIcon} />
              </div>
            )}
          </Col>
        </div>
      )}

      <Accordion {...content} />

      {!quantityInputHandler && footerRows?.length > 0 && (
        <div className="mt-3">
          {footerRows?.map(({ title, value, highlight }, index) => (
            <div
              key={`${title}_${index}`}
              className={classnames(styles.footerRow, { [styles.footerRowHighlighted]: highlight })}
              data-testid="table-order-footer-row"
            >
              <span>{title}</span>
              {isLoading ? (
                <div className="d-flex align-items-center" data-testid="table-order-loader">
                  <BeatLoader color="#8d8d8d" />
                </div>
              ) : (
                <span>{value}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductOrder;

import classnames from "classnames/bind";
import { PropagateLoader } from "react-spinners";
import styles from "./styles.module.scss";
import { ButtonHTMLAttributes } from "react";

const cx = classnames.bind(styles);

// eslint-disable-next-line
interface ButtonProps extends ButtonHTMLAttributes<any> {
  title: string;
  variant?: string;
  onClick?: () => void;
  dataTestId?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button = ({
  title,
  variant = "primary",
  dataTestId,
  onClick,
  isLoading = false,
  disabled,
  ...rest
}: ButtonProps) => {
  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      {...rest}
      data-testid={dataTestId}
      disabled={disabled || isLoading}
      onClick={handleButtonClick}
      className={cx("button", {
        primary: variant === "primary",
        secondary: variant === "secondary",
        disabled
      })}
    >
      {isLoading ? <PropagateLoader size={10} color="white" /> : title}
    </button>
  );
};

export default Button;

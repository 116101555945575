import { COMPANY_FLOW_TYPE, PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE, DRAWER_FLOW_TYPE } from "constants/flowTypes";
import { useParams } from "react-router-dom";
import useQueryPrl from "api/queries/useQueryPrl";
import useQueryProduct from "api/queries/useQueryProduct";
import useQueryCompany from "api/queries/useQueryCompany";
import { useQueryDrawer } from "api/queries";
import { HEADER_BACKGROUND_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from "constants/layoutColors";

const useGetData = () => {
  const { id = "123", type: flowType } = useParams();
  const {
    data: prlData,
    isLoading: isLoadingPrl,
    error: isErrorPrl
  } = useQueryPrl(id, {
    enabled: flowType === PRL_FLOW_TYPE
  });

  const {
    data: productData,
    isLoading: isLoadingProduct,
    error: isErrorProduct
  } = useQueryProduct(id, { enabled: flowType === PRODUCT_FLOW_TYPE });

  const {
    data: companyData,
    isLoading: isLoadingCompany,
    error: isErrorCompany
  } = useQueryCompany(id, { enabled: flowType === COMPANY_FLOW_TYPE });

  const {
    data: drawerData,
    isLoading: isLoadingDrawer,
    error: isErrorDrawer
  } = useQueryDrawer(id, { enabled: flowType === DRAWER_FLOW_TYPE });

  const getDataFlowType = () => {
    if (flowType === PRL_FLOW_TYPE) {
      return prlData;
    } else if (flowType === PRODUCT_FLOW_TYPE) {
      return productData;
    } else if (flowType === COMPANY_FLOW_TYPE) {
      return companyData;
    } else if (flowType === DRAWER_FLOW_TYPE) {
      return drawerData;
    }
  };

  const data = getDataFlowType();
  const isLoading =
    (flowType === PRL_FLOW_TYPE && isLoadingPrl) ||
    (flowType === PRODUCT_FLOW_TYPE && isLoadingProduct) ||
    (flowType === COMPANY_FLOW_TYPE && isLoadingCompany) ||
    (flowType === DRAWER_FLOW_TYPE && isLoadingDrawer);
  const isError =
    (flowType === PRL_FLOW_TYPE && isErrorPrl) ||
    (flowType === PRL_FLOW_TYPE && isErrorProduct) ||
    (flowType === COMPANY_FLOW_TYPE && isErrorCompany) ||
    (flowType === DRAWER_FLOW_TYPE && isErrorDrawer);

  const themes = {
    primaryColor:
      flowType === DRAWER_FLOW_TYPE
        ? drawerData?.merchant?.cpp_primary_color
        : flowType === COMPANY_FLOW_TYPE
        ? companyData?.address?.text
        : (productData || prlData)?.merchant?.cpp_primary_color ?? PRIMARY_COLOR,
    secondaryColor:
      flowType === DRAWER_FLOW_TYPE
        ? drawerData?.merchant?.cpp_secondary_color
        : flowType === COMPANY_FLOW_TYPE
        ? companyData?.address?.text
        : (productData || prlData)?.merchant?.cpp_secondary_color ?? SECONDARY_COLOR,
    headerColor:
      flowType === DRAWER_FLOW_TYPE
        ? drawerData?.merchant?.cpp_header_color
        : flowType === COMPANY_FLOW_TYPE
        ? companyData?.address?.text
        : (productData || prlData)?.merchant?.cpp_header_color ?? HEADER_BACKGROUND_COLOR
  };

  const isCutomerInfos = !!data?.customer.length;
  return { data, isLoading, isError, prlData, productData, companyData, drawerData, isCutomerInfos, themes };
};

export default useGetData;

// Types extracted from the Swagger editor
/*eslint-disable*/

/**
 * Describes customer log entity fields
 * @export
 * @interface CustomerLogResponseObject
 */
export interface CustomerLogResponseObject {
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof CustomerLogResponseObject
   */
  nameFirst?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof CustomerLogResponseObject
   */
  nameLast?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof CustomerLogResponseObject
   */
  phonenumber?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof CustomerLogResponseObject
   */
  email?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof CustomerLogResponseObject
   */
  companyName?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof CustomerLogResponseObject
   */
  companyAddress?: ResponseFieldObject;
}

/**
 * Describes fields for the document object
 * @export
 * @interface DocumentObject
 */
export interface DocumentObject {
  /**
   * Document UUID
   * @type {string}
   * @memberof DocumentObject
   */
  documentUuid?: string;
  /**
   * Unique url of the document
   * @type {string}
   * @memberof DocumentObject
   */
  documentUrl?: string;
  /**
   * Human readable lable of the document
   * @type {string}
   * @memberof DocumentObject
   */
  documentLabel?: string;
  /**
   * Original file name
   * @type {string}
   * @memberof DocumentObject
   */
  originalName?: string;
  /**
   * File mime type
   * @type {string}
   * @memberof DocumentObject
   */
  mimeType?: string;
  /**
   * File checksum
   * @type {string}
   * @memberof DocumentObject
   */
  checksum?: string;
}
/**

 * Describes Drawer object

 * @export

 * @interface DrawerObject

 */

export interface DrawerObject {
  /**
   * Unique identifier
   * @type {number}
   * @memberof DrawerObject
   */
  id?: number;
  /**
   * Specifies if the drawer is the default one
   * @type {boolean}
   * @memberof DrawerObject
   */
  isDefault?: boolean;
  /**
   * Specifies if the drawer is active
   * @type {boolean}
   * @memberof DrawerObject
   */
  isActive?: boolean;

  /**
   * User friendly name of the store
   * @type {string}
   * @memberof DrawerObject
   */
  payRequestUuid?: string;
  /**
   * User friendly name of the store
   * @type {string}
   * @memberof DrawerObject
   */

  name?: string;
  /**
   * Internal name of the store
   * @type {string}
   * @memberof DrawerObject
   */
  businessId?: string;
  /**
   * Notification phone number
   * @type {string}
   * @memberof DrawerObject
   */

  phone?: string;

  /**
   * Notification email address
   * @type {string}
   * @memberof DrawerObject
   */
  email?: string;
  /**
   * Link to payment portal
   * @type {string}
   * @memberof DrawerObject
   */
  drawerLink?: string;
  /**
   * Shortened link to payment portal
   * @type {string}
   * @memberof DrawerObject
   */
  drawerLinkShortened?: string;
  /**
   *
   * @type {DrawerObjectMerchant}
   * @memberof DrawerObject
   */
  merchant?: DrawerObjectMerchant;
  /**
   *
   * @type {DrawerObjectStore}
   * @memberof DrawerObject
   */
  store?: DrawerObjectStore;
  /**
   *
   * @type {Array<PaymentMethodObject>}
   * @memberof DrawerObject
   */
  paymentMethods?: Array<PaymentMethodObject>;
  /**
   *
   * @type {MerchantResultObjectFormFields}
   * @memberof DrawerObject
   */
  paymentMethod?: Array<PaymentMethodObject>;
  /**
   *
   * @type {MerchantResultObjectFormFields}
   * @memberof DrawerObject
   */
  formFields?: MerchantResultObjectFormFields;
}

/**
 *
 * @export
 * @interface DrawerObjectMerchant
 */
export interface DrawerObjectMerchant {
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  coreCompanyId?: string;
  /**
   *
   * @type {number}
   * @memberof DrawerObjectMerchant
   */
  numericCode?: number;
  /**
   * UUID of the default drawer. Normally same as Mercahnt's numericCode
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  defaultDrawerUuid?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  defaultDrawerLinkShortened?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  merchantLinkShortened?: string;

  /**
   *
   * @type {boolean}
   * @memberof DrawerObjectMerchant
   */
  isCompanySpecificPrlActive?: boolean;
  /**
   * Url to merchants logo
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  logo?: string;
  /**
   *
   * @type {LocalizedFieldObject}
   * @memberof DrawerObjectMerchant
   */
  name?: LocalizedFieldObject;
  /**
   *
   * @type {LocalizedFieldObject}
   * @memberof DrawerObjectMerchant
   */
  address?: LocalizedFieldObject;
  /**
   * Merchant phone
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_header_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_secondary_color?: string;
}

/**
 *
 * @export
 * @interface DrawerObjectStore
 */

export interface DrawerObjectStore {
  /**
   * Unique identifier
   * @type {number}
   * @memberof DrawerObjectStore
   */
  id?: number;
  /**
   * Specifies if the store is the default one
   * @type {boolean}
   * @memberof DrawerObjectStore
   */
  isDefault?: boolean;
  /**
   * Specifies if the store is active
   * @type {boolean}
   * @memberof DrawerObjectStore
   */
  isActive?: boolean;
  /**
   * User friendly name of the store
   * @type {string}
   * @memberof DrawerObjectStore
   */
  name?: string;
  /**
   * Internal name of the store
   * @type {string}
   * @memberof DrawerObjectStore
   */
  businessId?: string;
  /**
   * Notification phone number
   * @type {string}
   * @memberof DrawerObjectStore
   */
  phone?: string;
  /**
   * Notification email address
   * @type {string}
   * @memberof DrawerObjectStore
   */
  email?: string;
  /**
   * Postal Code of the store
   * @type {string}
   * @memberof DrawerObjectStore
   */
  postalCode?: string;
  /**
   * Address line of the store
   * @type {string}
   * @memberof DrawerObjectStore
   */
  address?: string;
  /**
   * City where the store is located
   * @type {string}
   * @memberof DrawerObjectStore
   */
  city?: string;
  /**
   * State where the store is located
   * @type {string}
   * @memberof DrawerObjectStore
   */
  state?: string;
  /**
   * Country where the store is located
   * @type {string}
   * @memberof DrawerObjectStore
   */
  country?: string;
}

/**
/**
 * Defines available currencies - \"XAF\" - \"EUR\" - \"USD\" - \"CAD\"
 * @export
 * @enum {string}
 */
export enum EnumCurrency {
  XAF = <any>"XAF",
  EUR = <any>"EUR",
  USD = <any>"USD",
  CAD = <any>"CAD"
}

/**
 * Defines payment method account types - \"MSISDN\" - \"NUMBER\" - \"STRING\"
 * @export
 * @enum {string}
 */
export enum EnumPaymentMethodAccountType {
  MSISDN = <any>"MSISDN",
  NUMBER = <any>"NUMBER",
  STRING = <any>"STRING"
}

/**
 * Defines available payment providers - \"CMYUPCC\" - \"MOMO\" - \"ORANGEWPAY\" - \"CMYOOMEEMONEYCC\" - \"CMEXPRESSUNIONCC\"
 * @export
 * @enum {string}
 */
export enum EnumPaymentProvider {
  CMYUPCC = <any>"CMYUPCC",
  MOMO = <any>"MOMO",
  ORANGEWPAY = <any>"ORANGEWPAY",
  CMYOOMEEMONEYCC = <any>"CMYOOMEEMONEYCC",
  CMEXPRESSUNIONCC = <any>"CMEXPRESSUNIONCC"
}

/**
 * Defines the status of the payment - \"OPEN\" - \"REVERSED\" - \"DEBITED\" - \"PENDING\" - \"INPROCESS\" - \"ERRORED\" - \"UNDERINVESTIGATION\" - \"ERROREDREFUNDED\" - \"SUCCESS\"
 * @export
 * @enum {string}
 */
export enum EnumPaymentStatus {
  OPEN = <any>"OPEN",
  REVERSED = <any>"REVERSED",
  DEBITED = <any>"DEBITED",
  PENDING = <any>"PENDING",
  INPROCESS = <any>"INPROCESS",
  ERRORED = <any>"ERRORED",
  UNDERINVESTIGATION = <any>"UNDERINVESTIGATION",
  ERROREDREFUNDED = <any>"ERROREDREFUNDED",
  SUCCESS = <any>"SUCCESS"
}
/**
 * Defines PRL statuses - \"OPEN\" - \"CANCELED\" - \"PAID\" - \"PARTIALLYPAID\" - \"EXPIRED\"
 * @export
 * @enum {string}
 */

export enum EnumPrlStatus {
  OPEN = <any>"OPEN",

  CANCELED = <any>"CANCELED",

  PAID = <any>"PAID",

  PARTIALLYPAID = <any>"PARTIALLYPAID",

  EXPIRED = <any>"EXPIRED"
}

/**
 * Defines PRL flow types - \"customer\" - \"product\" - \"company\"
 * @export
 * @enum {string}
 */
export enum EnumPrlType {
  Customer = <any>"customer",
  Product = <any>"product",
  Company = <any>"company"
}

/**
 *
 * @export
 * @interface ErrorObject
 */
export interface ErrorObject {
  /**
   * Original error response as is received from service provider, including header/body where applicable
   * @type {string}
   * @memberof ErrorObject
   */
  raw?: string;
  /**
   * Original error code sent by the service provider that is extracted (if available)
   * @type {string}
   * @memberof ErrorObject
   */
  providerErrorCode?: string;
  /**
   * Maviance Internal mapped error code
   * @type {string}
   * @memberof ErrorObject
   */
  mappedErrorCode?: string;
  /**
   * Custom notes/log/message from developer for easier error resolution by OPS team\"
   * @type {string}
   * @memberof ErrorObject
   */
  devMsg?: string;
  /**
   * Severity Level (NO|LOW|MEDIUM|HIGH) - will trigger notification actions in Smobilpay (e.g. alert ops on LOW and MEDIUM - alert management on HIGH)
   * @type {string}
   * @memberof ErrorObject
   */
  severity?: string;
  /**
   *
   * @type {Array<Translation>}
   * @memberof ErrorObject
   */
  customerMsg?: Array<Translation>;
}

/**
 * Sets locale and text of the field
 * @export
 * @interface LocalizedFieldObject
 */
export interface LocalizedFieldObject {
  /**
   *
   * @type {string}
   * @memberof LocalizedFieldObject
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof LocalizedFieldObject
   */
  lang?: string;
}

/**
 *
 * @export
 * @interface MerchantResultObject
 */
export interface MerchantResultObject {
  /**
   *
   * @type {string}
   * @memberof MerchantResultObject
   */
  coreCompanyId?: string;
  /**
   *
   * @type {number}
   * @memberof MerchantResultObject
   */
  numericCode?: number;
  /**

   * UUID of the default drawer. Normally same as Mercahnt's numericCode
   * @type {string}
   * @memberof MerchantResultObject
   */
  defaultDrawerUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MerchantResultObject
   */
  defaultDrawerLinkShortened?: string;

  /**
   *
   * @type {string}
   * @memberof MerchantResultObject
   */
  merchantLinkShortened?: string;
  /**
   *
   * @type {boolean}
   * @memberof MerchantResultObject
   */
  isCompanySpecificPrlActive?: boolean;
  /**
   * Url to merchants logo
   * @type {string}
   * @memberof MerchantResultObject
   */
  logo?: string;
  /**
   *
   * @type {LocalizedFieldObject}
   * @memberof MerchantResultObject
   */
  name?: LocalizedFieldObject;
  /**
   *
   * @type {LocalizedFieldObject}
   * @memberof MerchantResultObject
   */
  address?: LocalizedFieldObject;
  /**
   * Merchant phone
   * @type {string}
   * @memberof MerchantResultObject
   */
  phone?: string;
  /**
   *
   * @type {Array<PaymentMethodObject>}
   * @memberof MerchantResultObject
   */
  paymentMethods?: Array<PaymentMethodObject>;
  paymentMethod?: Array<PaymentMethodObject>;
  /**
   *
   * @type {MerchantResultObjectFormFields}
   * @memberof MerchantResultObject
   */
  formFields?: MerchantResultObjectFormFields;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_header_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_secondary_color?: string;
}

/**
 * Customer KYC fields rendering instrucitons for front end
 * @export
 * @interface MerchantResultObjectFormFields
 */
export interface MerchantResultObjectFormFields {
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof MerchantResultObjectFormFields
   */
  amount?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof MerchantResultObjectFormFields
   */
  reference?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldWithoutValueObject}
   * @memberof MerchantResultObjectFormFields
   */
  nameFirst?: ResponseFieldWithoutValueObject;
  /**
   *
   * @type {ResponseFieldWithoutValueObject}
   * @memberof MerchantResultObjectFormFields
   */
  nameLast?: ResponseFieldWithoutValueObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof MerchantResultObjectFormFields
   */
  phonenumber?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof MerchantResultObjectFormFields
   */
  companyName?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof MerchantResultObjectFormFields
   */
  companyAddress?: ResponseFieldObject;
}

/**
 * Response returned from pay endpoint. The status will always be PENDING
 * @export
 * @interface OrderResultObject
 */
export interface OrderResultObject {
  /**
   * Order ID
   * @type {number}
   * @memberof OrderResultObject
   */
  orderId?: number;
  /**
   * Order status
   * @type {string}
   * @memberof OrderResultObject
   */
  status?: string;
}

/**
 * Contains the results of a given document entity create/update or GET request.
 * @export
 * @interface OrderStatusObject
 */
export interface OrderStatusObject {
  /**
   * Order ID
   * @type {number}
   * @memberof OrderStatusObject
   */
  orderId?: number;
  /**
   *
   * @type {EnumPaymentStatus}
   * @memberof OrderStatusObject
   */
  status?: EnumPaymentStatus;
  /**
   *
   * @type {number}
   * @memberof OrderStatusObject
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStatusObject
   */
  ptn?: string;
  /**
   *
   * @type {string}
   * @memberof OrderStatusObject
   */
  quote?: number;
  /**
   *
   * @type {number}
   * @memberof OrderStatusObject
   */
  total?: number;
  /**
   *
   * @type {boolean}
   * @memberof OrderStatusObject
   */
  isPaymentInitiated?: boolean;
  /**
   *
   * @type {PaymentErrorObject}
   * @memberof OrderStatusObject
   */
  error?: PaymentErrorObject;
}

/**
 * Contains information about payment error
 * @export
 * @interface PaymentErrorObject
 */
export interface PaymentErrorObject {
  /**
   *
   * @type {number}
   * @memberof PaymentErrorObject
   */
  code?: number;
  /**
   * localized description
   * @type {Array<LocalizedFieldObject>}
   * @memberof PaymentErrorObject
   */
  description?: Array<LocalizedFieldObject>;
}

/**
 * Lists available payment methods
 * @export
 * @interface PaymentMethodObject
 */
export interface PaymentMethodObject {
  /**
   *
   * @type {EnumPaymentProvider}
   * @memberof PaymentMethodObject
   */
  paymentProviderId?: EnumPaymentProvider;
  /**
   * Payment method display name
   * @type {Array<LocalizedFieldObject>}
   * @memberof PaymentMethodObject
   */
  name?: Array<LocalizedFieldObject>;
  /**
   * JSON object of i18n payment instructions
   * @type {Array<LocalizedFieldObject>}
   * @memberof PaymentMethodObject
   */
  instructions?: Array<LocalizedFieldObject>;
  /**
   * JSON object of i18n input labels
   * @type {Array<LocalizedFieldObject>}
   * @memberof PaymentMethodObject
   */
  inputLabel?: Array<LocalizedFieldObject>;
  /**
   * Validation - provided as a regular expression
   * @type {string}
   * @memberof PaymentMethodObject
   */
  validationRegex?: string;
  /**
   *
   * @type {EnumPaymentMethodAccountType}
   * @memberof PaymentMethodObject
   */
  accountNumberType?: EnumPaymentMethodAccountType;
  /**
   * Minimum amount accepted by the provider
   * @type {number}
   * @memberof PaymentMethodObject
   */
  min?: number;
  /**
   * Maximum amount accepted by the provider
   * @type {number}
   * @memberof PaymentMethodObject
   */
  max?: number;
  /**
   *
   * @type {EnumCurrency}
   * @memberof PaymentMethodObject
   */
  currency?: EnumCurrency;
}

/**
 * Describes all of the data the can be used to create Company Specific PRL
 * @export
 * @interface PrlObjectCreateCompanyPrlRequest
 */
export interface PrlObjectCreateCompanyPrlRequest {
  /**
   * Drawer UUID
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  drawerUuid?: string;
  /**
   * Price specified by customer
   * @type {number}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  amount: number;
  /**
   * Reference from customer to the merchant
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  reference?: string;
  /**
   * Manually entered customer first name
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  nameFirst?: string;
  /**
   * Manually entered customer last name
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  nameLast?: string;
  /**
   * Manually entered customer phone
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  phonenumber?: string;
  /**
   * Manually entered customer company name
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  companyName?: string;
  /**
   * Manually entered customer company address
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlRequest
   */
  companyAddress?: string;

  email?: string;
}

/**
 * Contains a link of the newly created Company Specific PRL
 * @export
 * @interface PrlObjectCreateCompanyPrlResponse
 */
export interface PrlObjectCreateCompanyPrlResponse {
  /**
   * Uuid of the generated PRL
   * @type {string}
   * @memberof PrlObjectCreateCompanyPrlResponse
   */
  url?: string;
}

/**
 * Describes all of the data the can be used to create Product Specific PRL
 * @export
 * @interface PrlObjectCreateProductPrlRequest
 */
export interface PrlObjectCreateProductPrlRequest {
  /**
   * Product quantity selected by customer
   * @type {number}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  productId: number;
  /**
   * Product quantity selected by customer
   * @type {number}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  productQuantity: number;
  /**
   *
   * @type {EnumPaymentProvider}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  paymentMethod: EnumPaymentProvider;
  /**
   * Customer account/phone number
   * @type {string}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  accountNumber: string;
  /**
   * Notes from customer to the merchant
   * @type {string}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  notes?: string;
  /**
   * Manually entered customer first name
   * @type {string}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  nameFirst: string;
  /**
   * Manually entered customer last name
   * @type {string}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  nameLast: string;
  /**
   * Manually entered customer company name
   * @type {string}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  companyName?: string;
  /**
   * Manually entered customer company address
   * @type {string}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  companyAddress?: string;
  /**
   * Manually entered customer phone
   * @type {string}
   * @memberof PrlObjectCreateProductPrlRequest
   */
  phonenumber: string;
}

/**
 * Contains a link of the newly created Product Specific PRL
 * @export
 * @interface PrlObjectCreateProductPrlResponse
 */
export interface PrlObjectCreateProductPrlResponse {
  /**
   * Uuid of the generated PRL
   * @type {string}
   * @memberof PrlObjectCreateProductPrlResponse
   */
  url?: string;
}

/**
 * Describes all of the data the can be used to update Company Specific PRL
 * @export
 * @interface PrlObjectUpdateCompanyPrlRequest
 */
export interface PrlObjectUpdateCompanyPrlRequest {
  /**
   * Price specified by customer
   * @type {number}
   * @memberof PrlObjectUpdateCompanyPrlRequest
   */
  amount?: number;
  /**
   * Reference from customer to the merchant
   * @type {string}
   * @memberof PrlObjectUpdateCompanyPrlRequest
   */
  reference?: string;
  /**
   * Manually entered customer first name
   * @type {string}
   * @memberof PrlObjectUpdateCompanyPrlRequest
   */
  nameFirst?: string;
  /**
   * Manually entered customer last name
   * @type {string}
   * @memberof PrlObjectUpdateCompanyPrlRequest
   */
  nameLast?: string;
  /**
   * Manually entered customer phone
   * @type {string}
   * @memberof PrlObjectUpdateCompanyPrlRequest
   */
  phonenumber?: string;
  /**
   * Manually entered customer company name
   * @type {string}
   * @memberof PrlObjectUpdateCompanyPrlRequest
   */
  companyName?: string;
  /**
   * Manually entered customer company address
   * @type {string}
   * @memberof PrlObjectUpdateCompanyPrlRequest
   */
  companyAddress?: string;
}

/**
 * Describes all of the data the can be updated
 * @export
 * @interface PrlObjectUpdateRequest
 */
export interface PrlObjectUpdateRequest {
  /**
   *
   * @type {EnumPaymentProvider}
   * @memberof PrlObjectUpdateRequest
   */
  paymentMethod?: EnumPaymentProvider;
  /**
   * Customer account/phone number
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  accountNumber?: string;
  /**
   * Notes from customer to the merchant
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  notes?: string;
  /**
   * Manually entered customer first name
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  nameFirst?: string;
  /**
   * Manually entered customer last name
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  nameLast?: string;
  /**
   * Manually entered customer company name
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  companyName?: string;
  /**
   * Manually entered customer company address
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  companyAddress?: string;
  /**
   * Manually entered customer phone
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  phonenumber?: string;
  /**
   * Manually entered customer email
   * @type {string}
   * @memberof PrlObjectUpdateRequest
   */
  email?: string;
}

/**
 * Contains the results of a given document entity create/update or GET request.
 * @export
 * @interface PrlResultObject
 */
export interface PrlResultObject {
  /**
   *
   * @type {EnumPrlType}
   * @memberof PrlResultObject
   */
  prlFlowType?: EnumPrlType;
  /**
   *
   * @type {EnumPrlStatus}
   * @memberof PrlResultObject
   */
  status?: EnumPrlStatus;
  /**
   *
   * @type {number}
   * @memberof PrlResultObject
   */
  /**
   *
   * @type {number}
   * @memberof PrlResultObject
   */

  /**

   *

   * @type {number}

   * @memberof PrlResultObject

   */

  total?: number;
  orderId?: number;
  /**
  
  /**
   *
   * @type {Array<ProductLogResponseObject>}
   * @memberof PrlResultObject
   */
  product?: Array<ProductLogResponseObject>;
  /**
   *
   * @type {CustomerLogResponseObject}
   * @memberof PrlResultObject
   */
  customer?: CustomerLogResponseObject;
  /**
   *
   * @type {Array<PaymentMethodObject>}
   * @memberof PrlResultObject
   */
  paymentMethods?: Array<PaymentMethodObject>;
  paymentMethod?: Array<PaymentMethodObject>;
  /**
   *
   * @type {ProductResultObjectAmount}
   * @memberof PrlResultObject
   */
  amount?: ProductResultObjectAmount;
  /**
   *
   * @type {ProductResultObjectMerchant}
   * @memberof PrlResultObject
   */
  merchant?: ProductResultObjectMerchant;
  /**
  * This field is populated only for \"company\" type PRLs.  For the rest, the value is `null`.

  * @type {string}

  * @memberof PrlResultObject

  */

  drawerUuid?: string;

  /**
  /**
   *
   * @type {Array<DocumentObject>}
   * @memberof PrlResultObject
   */
  attachments?: Array<DocumentObject>;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_header_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_secondary_color?: string;
}

/**
 *
 * @export
 * @interface ProductEntity
 */
export interface ProductEntity {
  /**
   *
   * @type {number}
   * @memberof ProductEntity
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ProductEntity
   */
  productUuid?: string;
  /**
   *
   * @type {string}
   * @memberof ProductEntity
   */
  productExtId?: string;
  /**
   *
   * @type {string}
   * @memberof ProductEntity
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProductEntity
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof ProductEntity
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof ProductEntity
   */
  currency?: string;
}

/**
 * Describes product log entity fields
 * @export
 * @interface ProductLogResponseObject
 */
export interface ProductLogResponseObject {
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductLogResponseObject
   */
  name?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductLogResponseObject
   */
  description?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductLogResponseObject
   */
  price?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductLogResponseObject
   */
  quantity?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductLogResponseObject
   */
  reference?: ResponseFieldObject;
  /**
   *
   * @type {Array<DocumentObject>}
   * @memberof ProductLogResponseObject
   */
  attachments?: Array<DocumentObject>;
  /**
   * Quantity of the products in stock. The values can be from -1 (Infinite stock) to positive infinity
   * @type {number}
   * @memberof ProductLogResponseObject
   */
  availableQuantity?: number;
}

/**
 * Describes product log entity fields
 * @export
 * @interface ProductResponseObject
 */
export interface ProductResponseObject {
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResponseObject
   */
  name?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResponseObject
   */
  description?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResponseObject
   */
  price?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResponseObject
   */
  reference?: ResponseFieldObject;
  /**
   *
   * @type {Array<DocumentObject>}
   * @memberof ProductResponseObject
   */
  attachments?: Array<DocumentObject>;
}

/**
 *
 * @export
 * @interface ProductResultObject
 */
export interface ProductResultObject {
  /**
   * Product ID
   * @type {number}
   * @memberof ProductResultObject
   */
  id?: number;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResultObject
   */
  name?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResultObject
   */
  description?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResultObject
   */
  price?: ResponseFieldObject;
  /**
   *
   * @type {ResponseFieldObject}
   * @memberof ProductResultObject
   */
  reference?: ResponseFieldObject;
  /**
   *
   * @type {Array<DocumentObject>}
   * @memberof ProductResultObject
   */
  attachments?: Array<DocumentObject>;
  /**
   *
   * @type {Array<PaymentMethodObject>}
   * @memberof ProductResultObject
   */
  paymentMethods?: Array<PaymentMethodObject>;
  paymentMethod?: Array<PaymentMethodObject>;
  /**
   *
   * @type {ProductResultObjectMerchant}
   * @memberof ProductResultObject
   */
  merchant?: ProductResultObjectMerchant;
  /**
   *
   * * @type {ResponseFieldWithoutValueObject}
   * @memberof ProductResultObject
   */
  nameFirst?: ResponseFieldWithoutValueObject;
  /**

   *

   * @type {ResponseFieldWithoutValueObject}

   * @memberof ProductResultObject

   */

  nameLast?: ResponseFieldWithoutValueObject;

  /**

   *

   * @type {ResponseFieldWithoutValueObject}

   * @memberof ProductResultObject

   */

  phonenumber?: ResponseFieldWithoutValueObject;

  /**

   *

   * @type {ResponseFieldWithoutValueObject}

   * @memberof ProductResultObject

   */

  companyName?: ResponseFieldWithoutValueObject;

  /**

   *

   * @type {ResponseFieldWithoutValueObject}

   * @memberof ProductResultObject

   */

  companyAddress?: ResponseFieldWithoutValueObject;
  /**
   *
   * @type {ProductResultObjectAmount}
   * @memberof ProductResultObject
   */
  amount?: ProductResultObjectAmount;
  /**
   * Quantity of the products in stock. The values can be from -1 (Infinite stock) to positive infinity
   * @type {number}
   * @memberof ProductResultObject
   */
  quantity?: number;
}

/**
 *
 * @export
 * @interface ProductResultObjectAmount
 */
export interface ProductResultObjectAmount {
  /**
   * Total basket value
   * @type {number}
   * @memberof ProductResultObjectAmount
   */
  value?: number;
  /**
   * Defines if the amount can be edited by client
   * @type {boolean}
   * @memberof ProductResultObjectAmount
   */
  editable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductResultObjectAmount
   */
  allowDenomination?: boolean;
  /**
   *
   * @type {EnumCurrency}
   * @memberof ProductResultObjectAmount
   */
  currency?: EnumCurrency;
}

/**
 *
 * @export
 * @interface ProductResultObjectMerchant
 */
export interface ProductResultObjectMerchant {
  /**
   *
   * @type {string}
   * @memberof ProductResultObjectMerchant
   */
  coreCompanyId?: string;
  /**
   *
   * @type {number}
   * @memberof ProductResultObjectMerchant
   */
  numericCode?: number;
  /**
   * Url to merchants logo
   * @type {string}
   * @memberof ProductResultObjectMerchant
   */
  logo?: string;
  /**
   *
   * @type {LocalizedFieldObject}
   * @memberof ProductResultObjectMerchant
   */
  name?: LocalizedFieldObject;
  /**
   *
   * @type {LocalizedFieldObject}
   * @memberof ProductResultObjectMerchant
   */
  address?: LocalizedFieldObject;
  /**
   * Merchant phone
   * @type {string}
   * @memberof ProductResultObjectMerchant
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_header_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof DrawerObjectMerchant
   */
  cpp_secondary_color?: string;
}

/**
 * Contains request object for the quote
 * @export
 * @interface QuoteRequestObject
 */
export interface QuoteRequestObject {
  /**
   *
   * @type {EnumPaymentProvider}
   * @memberof QuoteRequestObject
   */
  paymentProviderId: EnumPaymentProvider;
  /**
   *
   * @type {number}
   * @memberof QuoteRequestObject
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof QuoteRequestObject
   */
  prlUuid?: string;
  /**
   *
   * @type {string}
   * @memberof QuoteRequestObject
   */
  productUuid?: string;
}

/**
 * Contains response object for the quote
 * @export
 * @interface QuoteResponseObject
 */
export interface QuoteResponseObject {
  /**
   *
   * @type {number}
   * @memberof QuoteResponseObject
   */
  quote?: number;
}

/**
 * Unique identifier for every request. Can be used for tracking down answers with technical support. Uses the ULID format (a time-based, sortable UUID) Note: this will be different for every request.
 * @export
 */
export type RequestIdObject = string;

/**
 *
 * @export
 * @interface ResponseFieldObject
 */
export interface ResponseFieldObject {
  /**
   * Field label
   * @type {Array<LocalizedFieldObject>}
   * @memberof ResponseFieldObject
   */
  label?: Array<LocalizedFieldObject>;
  /**
   * Field description
   * @type {Array<LocalizedFieldObject>}
   * @memberof ResponseFieldObject
   */
  description?: Array<LocalizedFieldObject>;
  /**
   * Field value
   * @type {string}
   * @memberof ResponseFieldObject
   */
  value?: string;
  /**
   * Regex for front end field validation
   * @type {string}
   * @memberof ResponseFieldObject
   */
  regex?: string;
  /**
   * Field type
   * @type {string}
   * @memberof ResponseFieldObject
   */
  type?: string;
  /**
   * Defines if the field can be edited
   * @type {boolean}
   * @memberof ResponseFieldObject
   */
  editable?: boolean;
  /**
   * Defines if the field is required
   * @type {boolean}
   * @memberof ResponseFieldObject
   */
  mandatory?: boolean;
}

/**
 *
 * @export
 * @interface ResponseFieldWithoutValueObject
 */
export interface ResponseFieldWithoutValueObject {
  /**
   * Field label
   * @type {Array<LocalizedFieldObject>}
   * @memberof ResponseFieldWithoutValueObject
   */
  label?: Array<LocalizedFieldObject>;
  /**
   * Field description
   * @type {Array<LocalizedFieldObject>}
   * @memberof ResponseFieldWithoutValueObject
   */
  description?: Array<LocalizedFieldObject>;
  /**
   * Regex for front end field validation
   * @type {string}
   * @memberof ResponseFieldWithoutValueObject
   */
  regex?: string;
  /**
   * Field type
   * @type {string}
   * @memberof ResponseFieldWithoutValueObject
   */
  type?: string;
  /**
   * Defines if the field can be edited
   * @type {boolean}
   * @memberof ResponseFieldWithoutValueObject
   */
  editable?: boolean;
  /**
   * Defines if the field is required
   * @type {boolean}
   * @memberof ResponseFieldWithoutValueObject
   */
  mandatory?: boolean;
}

/**
 *
 * @export
 * @interface Translation
 */
export interface Translation {
  /**
   * Target language to retrieve the translations for (e.g. 'en', 'fr', etc.)
   * @type {string}
   * @memberof Translation
   */
  language: string;
  /**
   * Translated text for this language
   * @type {string}
   * @memberof Translation
   */
  content: string;
}
export type PrlResultObjectTransformed = Omit<PrlResultObject, "customer"> & {
  customer: (ResponseFieldObject & { name: string })[];
};

export type ProductResultObjectTransformed = ProductResultObject & {
  customer: (ResponseFieldObject & { name: string })[];
  product: ProductLogResponseObject[];
};

export type MerchantResultObjectTransformed = MerchantResultObject & {
  customer: (ResponseFieldObject & { name: string })[];
  firstScreenFields: (ResponseFieldObject & { name: string })[];
};

/**
 *
 * @export
 * @interface MerchantResultObject
 */
export type DrawerResultObject = DrawerObject & {
  customer: (ResponseFieldObject & { name: string })[];
  firstScreenFields: (ResponseFieldObject & { name: string })[];
};


export interface ReceiptResponse {
  data?: any;
  filename: string;
}
import CircleCheckmarkIcon from "assets/icons/CircleCheckmarkIcon";
import classnames from "classnames";
import useGetData from "hooks/api/useGetData";
import { EnumPaymentProvider } from "types/api/api";
import { PaymentMethodPickerItemType } from "types/components/PaymentMethodPicker";
import { renderLogo } from "utils/renderLogo";
import styles from "./styles.module.scss";

interface PaymentMethodPickerItemProps extends PaymentMethodPickerItemType {
  active: boolean;
  setActiveItemId: (id: EnumPaymentProvider) => void;
}

const PaymentMethodPickerItem = ({
  id,
  active,
  setActiveItemId,
  text,
  disabled
}: PaymentMethodPickerItemProps) => {
  const { themes } = useGetData();
  return (
    <div
      role="button"
      data-testid="payment-method-picker-item"
      className={classnames(styles.paymentMethodItemWrapper, {
        [styles.paymentMethodItemWrapperActive]: active && !disabled,
        [styles.paymentMethodItemWrapperDisable]: disabled
      })}
      onClick={() => {
        if (!disabled && id) {
          setActiveItemId(id);
        }
      }}
    >
      <div
        className={classnames(styles.paymentMethodItemCard)}
        style={{
          border: active ? `3px solid ${themes.primaryColor}` : undefined,
          backgroundColor: active ? themes.primaryColor : undefined
        }}
      >
        <div
          className={styles.paymentMethodItemImage}
          style={{ backgroundImage: `url(${renderLogo(id)})` }}
        ></div>
        {!disabled && (
          <CircleCheckmarkIcon
            className={styles.checkmarkIcon}
            style={{ color: active ? themes.primaryColor : undefined }}
            color={themes.primaryColor}
          />
        )}
      </div>

      <div
        className={classnames(styles.paymentMethodItemText, "text-truncate")}
        style={{ color: active ? themes.primaryColor : undefined }}
      >
        {text}
      </div>
    </div>
  );
};

export default PaymentMethodPickerItem;

import { useMutation } from "@tanstack/react-query";
import { postCreateProduct } from "api";
import { PrlObjectCreateProductPrlRequest } from "types/api/api";

// eslint-disable-next-line
const useMutateCreateProductPrl = (options?: any) => {
  return useMutation((payload: PrlObjectCreateProductPrlRequest) => postCreateProduct(payload), options);
};

export default useMutateCreateProductPrl;

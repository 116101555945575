import { useMutation } from "@tanstack/react-query";
import { QuoteRequestObject } from "types/api/api";
import { getQuote } from "api/index";

// eslint-disable-next-line
const useMutateCreateQuote = (options?: any) => {
  return useMutation((payload: QuoteRequestObject) => getQuote(payload), options);
};

export default useMutateCreateQuote;

/**
 * Currency formatter function
 *
 * @param {Number} value - Value to be formatted
 * @param {String} locale
 * @param {String} currency
 * @returns {String} - Formatted value
 */
import { EnumCurrency } from "types/api/api";

const formatCurrency = (value = 0, currency: EnumCurrency = EnumCurrency.XAF, locale = "fr-CM") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: String(currency)
  });

  const formattedValue = formatter.format(value);

  return formattedValue;
};

export default formatCurrency;

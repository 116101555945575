import styles from "./styles.module.scss";
import { DocumentObject } from "types/api/api";
import { MouseEvent } from "react";

interface AttachmentProps {
  items?: DocumentObject[];
  onClick: (e: MouseEvent, file: DocumentObject) => void;
}

const Attachment = ({ items, onClick }: AttachmentProps) => {
  return (
    <>
      <div className={styles.attachmentContainer} data-testid="attachment">
        {items?.map((file, i) => {
          return (
            <div className={styles.attachmentItem} key={i} data-testid="attachment-item">
              <button onClick={(e) => onClick(e, file)} className={styles.fileLink + " ps-2 btn btn-link"}>
                {file?.documentLabel}
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Attachment;

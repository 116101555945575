import { useIsFetching } from "@tanstack/react-query";
import { useMutateCreateCompanyPrl, useMutateCreateProductPrl, useMutatePay } from "api/mutations";
import useMutateCreateQuote from "api/mutations/useMutateCreateQuote";
import { useQueryStatus } from "api/queries";
import Button from "components/base/Button";
import StepperNavigation from "components/base/Stepper/components/StepperNavigation";
import { COMPANY_FLOW_TYPE, DRAWER_FLOW_TYPE, PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import { useAppContext, useAppDispatch } from "context/AppContext";
import useGetData from "hooks/api/useGetData";
import useGetQuote from "hooks/api/useGetQuote";
import useGetStatus from "hooks/api/useGetStatus";
import useUser from "hooks/useUser";
import useGetPrice from "hooks/utils/useGetPrice";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  EnumPaymentStatus,
  PrlObjectCreateCompanyPrlRequest,
  PrlObjectCreateProductPrlRequest,
  PrlObjectUpdateRequest
} from "types/api/api";
import { PrlFormDataObject } from "types/components/ProductPrl";
import { StepperProps } from "types/components/Stepper";
import formatCurrency from "utils/formatCurrency";
import FlowTypeModal from "./FlowTypeModal";
import styles from "./styles.module.scss";
import LoadingScreen from "./base/LoadingScreen";

const PageStepper = ({ children, activeStepIndex, setActiveStepIndex }: StepperProps) => {
  const { t } = useTranslation();
  const { clearData, updateUser, saveUserData } = useUser();
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [prlUuid, setPrlUuid] = React.useState<string | null>(null);
  const { totalPrice } = useGetPrice();
  const [initiatePayment, setInitiatePayment] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { isLoading: isLoadingQuote } = useGetQuote();

  const appContext = useAppContext();
  /* eslint-disable */
  const { id = "123", type: flowType } = useParams();
  const { mutateAsync: mutateCreateProductPrl, isLoading: isLoadingCreateProduct } =
    useMutateCreateProductPrl();
  const { mutateAsync: mutateCreateCompanyPrl, isLoading: isLoadingCreateCompany } =
    useMutateCreateCompanyPrl();
  const { mutateAsync: mutatePayPrl, data: payPrlData } = useMutatePay(id);

  const { mutateAsync: mutatePay, data: payData } = useMutatePay(prlUuid || "");

  const {
    isCutomerInfos,
    productData,
    isLoading: isLoadingData,
    prlData,
    companyData,
    drawerData,
    themes
  } = useGetData();

  const imgUrl =
    flowType === DRAWER_FLOW_TYPE
      ? drawerData?.merchant?.logo
      : flowType === COMPANY_FLOW_TYPE
      ? companyData?.logo
      : (productData || prlData)?.merchant?.logo;

  // ==================== PRL RELATED CODE  ==================== //
  const [statusPollingInterval, setStatusPollingInterval] = useState(2000);
  const {
    data: dataStatus,
    isDone: isDoneStatus,
    isPending: isPendingStatus,
    isError: isErrorStatus,
    isOpen: isOpenStatus
  } = useGetStatus({ refetchInterval: statusPollingInterval });

  useEffect(() => {
    if (dataStatus) {
      if (dataStatus && dataStatus.isPaymentInitiated) {
        setStatusPollingInterval(1000);
      } else {
        setStatusPollingInterval(0);
      }
    }
  }, [dataStatus?.isPaymentInitiated]);

  const isPayFetching = useIsFetching(["pay"]);
  const isPayLoading =
    isPayFetching > 0 || isPendingStatus || (dataStatus?.isPaymentInitiated && isOpenStatus);

  // -- Required for prl redirection
  useEffect(() => {
    if (isDoneStatus || isErrorStatus) {
      navigate(`/prl/${dataStatus?.orderId}/status/${id}`);
    }
  }, [id, navigate, isDoneStatus, flowType, isErrorStatus]);

  // ==================== DRAWER RELATED CODE  ==================== //
  const {
    mutateAsync: mutateCreateQuote,
    data: dataQuote,
    isLoading: isLoadingCreateQuote
  } = useMutateCreateQuote();

  const footerRows = [
    {
      title: t("yourOrder.subTotal"),
      value: formatCurrency(Number(appContext?.amount))
    },
    {
      title: t("yourOrder.fees"),
      value: formatCurrency(dataQuote?.quote),
      highlight: true
    },
    {
      title: t("yourOrder.total"),
      value: formatCurrency(Number(dataQuote?.quote || 0) + Number(appContext?.amount))
    }
  ];

  const content = {
    headings: [],
    rows: []
  };

  const methods = useForm<PrlFormDataObject>({
    mode: "onChange",
    defaultValues: {
      amount: undefined,
      productQuantity: 1,
      paymentInput: "",
      reference: ""
    }
  });

  // ----------- required for drawer quote and product quote --------------
  useEffect(() => {
    async function fetchData() {
      try {
        await mutateCreateQuote({
          paymentProviderId: appContext.paymentMethod,
          amount: Number(appContext?.amount),
          prlUuid: prlUuid || ""
        }).then((e) => updateUser({ quote: e.quote }));
      } catch (error) {}
    }

    if (initiatePayment && flowType === PRODUCT_FLOW_TYPE) {
      dispatch({
        type: "add",
        payload: {
          isProduct: true
        }
      });
      initiateProductAndPrlPayment();
    }
    /* eslint-disable */
    if (prlUuid) fetchData();
  }, [prlUuid]);

  // ------------- This is used for both drawer and product ----------------
  const initiateProductAndPrlPayment = async (data?: PrlObjectUpdateRequest) => {
    setInitiatePayment(true);
    updateUser({
      prlUuid: prlUuid,
      paymentMethod: appContext.paymentMethod,
      accountNumber: appContext.paymentInput,
      notes: undefined,
      phonenumber: appContext.paymentInput
    });

    if (data) {
      await mutatePayPrl(data, {
        onSuccess: (e) => {
          saveUserData(`${e.orderId}`, {
            prlUuid: prlUuid,
            paymentMethod: appContext.paymentMethod,
            accountNumber: appContext.paymentInput,
            notes: undefined,
            phonenumber: appContext.paymentInput
          });
        }
      });
    } else {
      await mutatePay(
        {
          paymentMethod: appContext.paymentMethod,
          accountNumber: appContext.paymentInput,
          notes: undefined,
          phonenumber: appContext.paymentInput
        },
        {
          onSuccess: (e) => {
            saveUserData(`${e.orderId}`, {
              prlUuid: prlUuid,
              paymentMethod: appContext.paymentMethod,
              accountNumber: appContext.paymentInput,
              notes: undefined,
              phonenumber: appContext.paymentInput
            });
          }
        }
      );
    }
  };

  // ==================== PRODUCT RELATED CODE  ==================== //
  const productQuantityWatcher = methods.watch("productQuantity");
  const productAvailable = Number(productData?.quantity) === 0 ? false : true;
  const productDifferencecheck =
    (Number(productData?.quantity) === -1 && false) ||
    (Number(productData?.quantity) !== -1 && productQuantityWatcher > Number(productData?.quantity));

  // ==================== GLOBAL RELATED CODE  ==================== //
  const closeModal = () => setShowModal(false);

  const handleNext = () => {
    if (isCutomerInfos) {
      if (activeStepIndex === 1) {
        setShowModal(true);
        return;
      }
      setActiveStepIndex(activeStepIndex + 1);
    } else {
      setShowModal(true);
      return;
    }
  };

  const renderContent = () => {
    if (children && children.length > 0) {
      return React.Children.map(children, (child, index) => (index === activeStepIndex ? child : null));
    }
    return null;
  };

  const onConfirmation = async () => {
    setInitiatePayment(true);
  };

  const SubmitForm = async () => {
    await methods.handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (initiatePayment) {
      SubmitForm();
    }
  }, [initiatePayment]);

  const onSubmit = async (data: any) => {
    // For Product
    if (flowType === PRODUCT_FLOW_TYPE) {
      if (flowType === PRODUCT_FLOW_TYPE && initiatePayment) {
        const payload: PrlObjectCreateProductPrlRequest = {
          productId: productData?.id || 0,
          paymentMethod: appContext?.paymentMethod,
          accountNumber: appContext?.paymentInput,
          productQuantity: appContext.productQuantity,
          phonenumber: appContext?.paymentInput,
          nameFirst: data?.nameFirst,
          nameLast: data?.nameLast,
          notes: undefined
        };

        await mutateCreateProductPrl(payload)
          .then((data) => {
            const url = data.url as string;
            const prluuid = url.substring(url?.indexOf("prl/") + 4);
            setPrlUuid(prluuid);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      dispatch({
        type: "add",
        payload: {
          ...data,
          companyName: String(productData?.merchant?.name?.text),
          amount: totalPrice || 0
        }
      });
    }

    // For Drawer
    if (flowType === DRAWER_FLOW_TYPE) {
      const commonData: PrlObjectCreateCompanyPrlRequest = {
        drawerUuid: id,
        amount: Number(appContext.amount) || data?.amount,
        reference: appContext.reference || data?.reference,
        nameFirst: data?.nameFirst,
        nameLast: data?.nameLast,
        phonenumber: data?.phonenumber ? appContext.paymentInput : undefined,
        companyName: data?.companyName,
        companyAddress: data?.companyAddress,
        email: data?.email || undefined
      };

      if (isCutomerInfos && !initiatePayment) {
        if (activeStepIndex === 1) {
          setPrlUuid(null);
          setIsLoading(true);
          updateUser(commonData);
          await mutateCreateCompanyPrl(commonData)
            .then((data) => {
              const url = data.data.url as string;
              const prluuid = url.substring(url?.indexOf("prl/") + 4);
              setPrlUuid(prluuid);
            })
            .finally(() => {
              setIsLoading(false);
            });

          // Skip the confirmation modal
          setInitiatePayment(true);
        }
      } else if (!isCutomerInfos && !initiatePayment) {
        if (activeStepIndex === 0) {
          setPrlUuid(null);
          setIsLoading(true);
          updateUser(commonData);
          await mutateCreateCompanyPrl(commonData)
            .then((data) => {
              const url = data.data.url as string;
              const prluuid = url.substring(url?.indexOf("prl/") + 4);
              setPrlUuid(prluuid);
            })
            .finally(() => {
              setIsLoading(false);
            });
          // Skip the confirmation modal
          setInitiatePayment(true);
        }
      }

      if (initiatePayment) {
        dispatch({
          type: "add",
          payload: {
            isProduct: false
          }
        });
        initiateProductAndPrlPayment();
      }

      dispatch({
        type: "add",
        payload: data
      });
    }

    // For Prl
    if (flowType === PRL_FLOW_TYPE) {
      if (flowType === PRL_FLOW_TYPE && initiatePayment) {
        const payload: PrlObjectUpdateRequest = {
          paymentMethod: appContext?.paymentMethod,
          accountNumber: appContext?.paymentInput,
          phonenumber: appContext?.paymentInput,
          nameFirst: data?.nameFirst,
          nameLast: data?.nameLast,
          email: data?.email || undefined,
          notes: undefined
        };

        initiateProductAndPrlPayment(payload);

        // await mutatePayPrl(payload).then((data) => {
        //   console.log("Data is : ", data);
        // });
      }

      dispatch({
        type: "add",
        payload: {
          ...data,
          companyName: String(productData?.merchant?.name?.text),
          amount: totalPrice || 0
        }
      });
    }

    handleNext();
  };

  // ----- This for the final status check --------------------------------
  const orderId = payPrlData?.orderId || payData?.orderId;
  const [isFinalStatus, setIsFinalStatus] = useState(false);
  const { isFetching, data } = useQueryStatus(String(orderId), {
    enabled: !!orderId && !isFinalStatus,
    refetchInterval: 7000
  });

  /* eslint-disable */
  const isDone =
    !isFetching &&
    (data?.status === EnumPaymentStatus.SUCCESS ||
      data?.status === EnumPaymentStatus.ERROREDREFUNDED ||
      data?.status === EnumPaymentStatus.ERRORED);

  useEffect(() => {
    setIsFinalStatus(isDone);
    if (isDone) {
      closeModal();
      navigate(`/prl/${orderId}/status/${prlUuid}`);
      localStorage.clear();
    }
  }, [isDone]);

  useEffect(() => {
    if (isDoneStatus || isErrorStatus) {
      navigate(`/prl/${dataStatus?.orderId}/status/${id}`);
    }
  }, [id, navigate, isDoneStatus, flowType, isErrorStatus]);

  const getDrawerStepperButtonTitle = () => {
    if (activeStepIndex === 0) {
      if (isCutomerInfos) {
        return t("firstScreen.buttonTitle1");
      } else {
        return t("firstScreen.buttonTitle2");
      }
    }
    return t("firstScreen.buttonTitle3", { amount: formatCurrency(Number(appContext?.amount || 0)) });
  };

  return (
    <FormProvider {...methods}>
      {(initiatePayment || isPayLoading) && <LoadingScreen type="dark" isLoading isPropagate={false} />}
      <div>
        {initiatePayment}
        {isPayLoading}
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <StepperNavigation
          activeStepIndex={activeStepIndex}
          children={children}
          customerInfo={isCutomerInfos}
          secondaryColor={themes.secondaryColor}
        />
        <div>{renderContent()}</div>

        <div className={activeStepIndex > 0 ? styles.buttonWrapper2 : styles.buttonWrapper}>
          {/* FOR PRODUCT */}
          {flowType === PRODUCT_FLOW_TYPE && activeStepIndex > 0 && (
            <div className="mb-4">
              <Button
                variant="secondary"
                title={t("secondScreen.buttonTitle2")}
                dataTestId={"open-modal-button"}
                type="submit"
                onClick={() => {
                  setActiveStepIndex(0);
                  clearData();
                }}
                style={{ backgroundColor: !isLoadingCreateProduct ? themes?.secondaryColor : undefined }}
                disabled={isLoadingCreateProduct || !productAvailable}
              />
            </div>
          )}

          {flowType === PRODUCT_FLOW_TYPE && (
            <Button
              variant="primary"
              dataTestId={"submit-button"}
              className="order-1"
              type="submit"
              disabled={
                !methods.formState.isValid ||
                isLoadingCreateProduct ||
                !productAvailable ||
                productDifferencecheck
              }
              isLoading={isLoadingData}
              title={
                activeStepIndex === 0
                  ? t("firstScreen.buttonTitle1")
                  : t("firstScreen.buttonTitle3", { amount: formatCurrency(totalPrice) })
              }
              style={{
                backgroundColor:
                  methods.formState.isValid &&
                  !isLoadingCreateProduct &&
                  productAvailable &&
                  !productDifferencecheck
                    ? themes?.primaryColor
                    : undefined
              }}
            />
          )}

          {/* FOR DRAWER */}
          {flowType === DRAWER_FLOW_TYPE && activeStepIndex > 0 && (
            <div className="mb-4">
              <Button
                variant="secondary"
                title={t("secondScreen.buttonTitle2")}
                onClick={() => {
                  setActiveStepIndex(0);
                  clearData();
                }}
                disabled={isLoadingCreateCompany}
                style={{
                  backgroundColor: !isLoadingCreateCompany ? themes?.secondaryColor : undefined
                }}
              />
            </div>
          )}

          {flowType === DRAWER_FLOW_TYPE && (
            <Button
              variant="primary"
              dataTestId={"submit-button"}
              type="submit"
              disabled={!methods.formState.isValid || isLoadingCreateCompany}
              isLoading={isLoading}
              title={getDrawerStepperButtonTitle()}
              style={{
                backgroundColor:
                  methods.formState.isValid && !isLoadingCreateCompany ? themes?.primaryColor : undefined
              }}
            />
          )}

          {/* FOR PRL */}
          {flowType === PRL_FLOW_TYPE && activeStepIndex > 0 && (
            <div className="mb-4">
              <Button
                variant="secondary"
                title={t("secondScreen.buttonTitle2")}
                dataTestId={"open-modal-button"}
                type="submit"
                onClick={() => {
                  setActiveStepIndex(0);
                  clearData();
                }}
                style={{
                  backgroundColor: themes?.secondaryColor
                }}
              />
            </div>
          )}

          {flowType === PRL_FLOW_TYPE && (
            <Button
              variant="primary"
              dataTestId={"submit-button"}
              className="order-1"
              type="submit"
              disabled={!methods.formState.isValid}
              isLoading={isLoading}
              title={
                activeStepIndex === 0
                  ? t("firstScreen.buttonTitle1")
                  : t("firstScreen.buttonTitle3", { amount: formatCurrency(totalPrice) })
              }
              style={{
                backgroundColor: methods.formState.isValid ? themes?.primaryColor : undefined
              }}
            />
          )}
        </div>

        <FlowTypeModal
          flowType={flowType || ""}
          showModal={showModal}
          closeModal={closeModal}
          onConfirmation={onConfirmation}
          isLoadingCreateQuote={isLoadingCreateQuote}
          initiatePayment={initiatePayment}
          content={content}
          footerRows={footerRows}
          isLoadingQuote={isLoadingQuote}
        />
      </form>
    </FormProvider>
  );
};

export default PageStepper;

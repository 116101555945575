import React from "react";
import styles from "./styles.module.scss";
import StepperNavigationItem from "./components/StepperNavigationItem";
import PropTypes from "prop-types";
import { SECONDARY_COLOR } from "constants/layoutColors";

const StepperNavigation = ({
  children,
  activeStepIndex,
  customerInfo = false,
  secondaryColor = SECONDARY_COLOR
}) => {
  return (
    <div className={styles.stepperNavigationWrapper}>
      {!customerInfo ? (
        <StepperNavigationItem title={children[0].props["data-nav"]} number={1} key={0} active={true} />
      ) : (
        <>
          {children?.map((item, index) => (
            <StepperNavigationItem
              title={item.props["data-nav"]}
              number={Number(index) + 1}
              key={index}
              active={index === activeStepIndex}
              secondaryColor={secondaryColor}
            />
          ))}
        </>
      )}
    </div>
  );
};

StepperNavigation.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  activeStepIndex: PropTypes.number
};

export default StepperNavigation;

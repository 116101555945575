import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import formatCurrency from "utils/formatCurrency";
import { EnumCurrency, PaymentMethodObject } from "types/api/api";

interface AlertAmountProps {
  paymentMethods: PaymentMethodObject[];
  productBasketTotal: number;
  currency?: EnumCurrency;
}

const AlertAmount = ({ paymentMethods, productBasketTotal, currency }: AlertAmountProps) => {
  const { t } = useTranslation();

  const renderAmountAlert = () => {
    // Array of minimums
    const minArr = paymentMethods?.map((method) => method.min || 0);

    // Array of maximums
    const maxArr = paymentMethods?.map((method) => method.max || 0);

    const amountTooHigh =
      productBasketTotal > Math.min(...minArr) && productBasketTotal > Math.max(...maxArr);
    const amountTooLow = productBasketTotal < Math.min(...minArr);

    const getAmount = () => {
      if (amountTooHigh) {
        return formatCurrency(Math.max(...maxArr), currency);
      }

      if (amountTooLow) {
        return formatCurrency(Math.min(...minArr), currency);
      }

      return 0;
    };

    if (amountTooHigh || amountTooLow) {
      return (
        <Alert variant="danger" data-testid="alert-amount">
          {t(amountTooHigh ? "firstScreen.alertTextTooHigh" : "firstScreen.alertTextTooLow", {
            amount: getAmount()
          })}
        </Alert>
      );
    }

    return null;
  };

  return renderAmountAlert();
};

export default AlertAmount;

import ButtonDropdown from "components/base/ButtonDropdown/ButtonDropdown";
import { COMPANY_FLOW_TYPE, DRAWER_FLOW_TYPE } from "constants/flowTypes";
import useGetData from "hooks/api/useGetData";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { BUTTON_DROPDOWN_ITEMS } from "../../../constants/componentsStaticData";
import styles from "./styles.module.scss";

const Header = () => {
  const { i18n } = useTranslation();
  const { type: flowType } = useParams();
  const switchLanguage = async (lang: string) => {
    await i18n.changeLanguage(lang);
  };
  const { prlData, companyData, productData, isLoading, drawerData, themes } = useGetData();

  const data = {
    imgUrl:
      flowType === DRAWER_FLOW_TYPE
        ? drawerData?.merchant?.logo
        : flowType === COMPANY_FLOW_TYPE
        ? companyData?.logo
        : (productData || prlData)?.merchant?.logo,
    onLanguageSelect: switchLanguage,
    isLoading,
    title:
      flowType === DRAWER_FLOW_TYPE
        ? drawerData?.merchant?.name?.text
        : flowType === COMPANY_FLOW_TYPE
        ? companyData?.name?.text
        : (prlData || productData)?.merchant?.name?.text,
    description:
      flowType === DRAWER_FLOW_TYPE
        ? drawerData?.merchant?.address?.text
        : flowType === COMPANY_FLOW_TYPE
        ? companyData?.address?.text
        : (productData || prlData)?.merchant?.address?.text
  };

  return (
    <div
      className={styles.headerWrapper}
      style={{ backgroundColor: themes.headerColor }}
      data-testid="header"
    >
      <div className={styles.headerContainer}>
        {!isLoading ? (
          <>
            <div className={styles.headerContentBox}>
              <div
                data-testid="header-logo"
                className={styles.headerLogo}
                style={{ backgroundImage: `url(${data.imgUrl})` }}
              ></div>

              <div className={styles.textBox}>
                <div className={styles.headerTitle}>{data.title}</div>
                <div className={styles.headerDescription}>{data.description}</div>
              </div>
            </div>
            <div className={styles.buttonDropdownWrapper}>
              <ButtonDropdown onSelect={data.onLanguageSelect} items={BUTTON_DROPDOWN_ITEMS} />
            </div>
          </>
        ) : (
          <div style={{ margin: "0 auto", padding: "22px 0" }}>
            <ClipLoader size={50} color="#242424" data-testid="header-loader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

import Input from "components/base/Input/Input";
import PhoneNumberInput from "components/base/PhoneNumberInput";
import { MOBILE_MONEY_REGEX } from "constants/regex";
import { t } from "i18next";
import { Control, FieldErrors } from "react-hook-form";
import { EnumPaymentMethodAccountType, PaymentMethodObject } from "types/api/api";
import createRegExpFromString from "utils/createRegExpFromString";

interface ArgsType {
  selectedPaymentMethod?: PaymentMethodObject | null;
  // eslint-disable-next-line
  i18n: any;
  errors: FieldErrors;
  control: Control;
  // eslint-disable-next-line
  register: any;
}

const renderPaymentMethodInput = ({
  selectedPaymentMethod = null,
  errors,
  i18n,
  control,
  register
}: ArgsType) => {
  if (selectedPaymentMethod) {
    switch (selectedPaymentMethod.accountNumberType) {
      case EnumPaymentMethodAccountType.MSISDN:
        return (
          <PhoneNumberInput
            data-testid="phone-input"
            id="paymentInput"
            key={`dynamic_uuid_${
              selectedPaymentMethod?.inputLabel?.find((l) => l.lang === i18n.language)?.text
            }`}
            control={control}
            rules={{
              required: {
                value: true,
                message: t("firstScreen.requiredInput")
              },
              pattern: {
                value: createRegExpFromString(selectedPaymentMethod?.validationRegex || MOBILE_MONEY_REGEX),
                message: t("firstScreen.invalidNumber")
              }
            }}
            name="paymentInput"
            message={errors["paymentInput"] && errors["paymentInput"].message}
            error={errors["paymentInput"]}
            label={selectedPaymentMethod?.inputLabel?.find((l) => l.lang === i18n.language)?.text}
          />
        );
      // case EnumPaymentMethodAccountType.MSISDN:
      //   return (
      //     <Input
      //       data-testid="text-input"
      //       id="paymentInput"
      //       type="text"
      //       message={errors["paymentInput"] && errors["paymentInput"].message}
      //       error={errors["paymentInput"]}
      //       label={selectedPaymentMethod?.inputLabel?.find((l) => l?.lang === i18n.language)?.text}
      //       {...register("paymentInput", {
      //         required: {
      //           value: true,
      //           message: t("firstScreen.requiredInput")
      //         },
      //         pattern: {
      //           value: createRegExpFromString(selectedPaymentMethod?.validationRegex || ""),
      //           message: t("firstScreen.invalidNumber")
      //         }
      //       })}
      //     />
      //   );
      case EnumPaymentMethodAccountType.STRING:
        return (
          <Input
            data-testid="text-input"
            id="paymentInput"
            type="text"
            message={errors["paymentInput"] && errors["paymentInput"].message}
            error={errors["paymentInput"]}
            label={selectedPaymentMethod?.inputLabel?.find((l) => l?.lang === i18n.language)?.text}
            {...register("paymentInput", {
              required: {
                value: true,
                message: t("firstScreen.requiredInput")
              },
              pattern: {
                value: createRegExpFromString(selectedPaymentMethod?.validationRegex || ""),
                message: t("firstScreen.invalidNumber")
              }
            })}
          />
        );
      case EnumPaymentMethodAccountType.NUMBER:
        return (
          <Input
            data-testid="text-input"
            id="paymentInput"
            type="text"
            message={errors["paymentInput"] && errors["paymentInput"].message}
            error={errors["paymentInput"]}
            label={selectedPaymentMethod?.inputLabel?.find((l) => l?.lang === i18n.language)?.text}
            {...register("paymentInput", {
              required: {
                value: true,
                message: t("firstScreen.requiredInput")
              },
              pattern: {
                value: createRegExpFromString(selectedPaymentMethod?.validationRegex || ""),
                message: t("firstScreen.invalidNumber")
              }
            })}
          />
        );
      default:
        return <Input id="paymentInput" disabled />;
    }
  }
};

export default renderPaymentMethodInput;

import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./styles.module.scss";

const Layout = ({ children }) => {
  return (
    <Row className="g-0">
      <Col md="6" className="d-none d-md-block">
        <div className={styles.layoutImage} data-testid="layout_image">
          <div className={styles.overlay}></div>
        </div>
      </Col>
      <Col xs="12" md="6" className={`position-relative ${styles.formContainer}`}>
        <div className={styles.layoutContentContainer}>{children}</div>
      </Col>
    </Row>
  );
};

Layout.propTypes = {
  children: PropTypes.object
};

export default Layout;

import classnames from "classnames/bind";
import React from "react";
import styles from "./styles.module.scss";

const cx = classnames.bind(styles);

interface InputProps {
  label?: string;
  id: string;
  disabled?: boolean;
  dataTestId?: string;
  message?: string;
  mandatory?: boolean;
  defaultValue?: string;
  type?: string;
  error?: boolean;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef(
  (
    {
      label,
      id,
      dataTestId,
      disabled,
      message,
      mandatory,
      error,
      placeholder,
      onChange,
      ...rest
    }: InputProps,
    // eslint-disable-next-line
    ref: any
  ) => {
    return (
      <div className={cx(styles.wrapper, { wrapperError: error, wrapperDisabled: disabled })}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {mandatory ? `${label} *` : label}
          </label>
        )}
        <input
          ref={ref}
          disabled={disabled}
          data-testid={dataTestId}
          data-cy={mandatory ? `input-mandatory` : "input"}
          id={id}
          className={styles.input}
          placeholder={placeholder || label}
          onChange={onChange}
          {...rest}
        />
        {message && (
          <span className={styles.message} role="alert">
            {message}
          </span>
        )}
      </div>
    );
  }
);

export default Input;

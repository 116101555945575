import { useMutation } from "@tanstack/react-query";
import { postPay } from "api";
import { PrlObjectUpdateRequest } from "types/api/api";

// eslint-disable-next-line
const useMutatePay = (id: string, options?: any) => {
  return useMutation((payload: PrlObjectUpdateRequest) => postPay(id, payload), options);
};

export default useMutatePay;

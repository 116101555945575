import * as React from "react";

function CircleErrormarkIcon() {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45" cy="45" r="45" fill="#C02626" />
      <path
        d="M58.6725 31.5576L33.5575 56.6726"
        stroke="white"
        stroke-width="10.47"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.5575 31.5576L58.6725 56.6726"
        stroke="white"
        stroke-width="10.47"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

const MemoCircleCheckmarkIcon = React.memo(CircleErrormarkIcon);
export default MemoCircleCheckmarkIcon;

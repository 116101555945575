import { COMPANY_FLOW_TYPE, DRAWER_FLOW_TYPE } from "constants/flowTypes";
import useGetData from "hooks/api/useGetData";
import useGetPrice from "hooks/utils/useGetPrice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaymentMethodPickerItemType } from "types/components/PaymentMethodPicker";

const useGetPaymentMethodContent = ({
  productQuantityWatcher
}: {
  productQuantityWatcher?: number;
}): PaymentMethodPickerItemType[] => {
  const { data } = useGetData();
  const { type } = useParams();
  const { subtotalPrice } = useGetPrice({ productQuantityWatcher });

  const { i18n } = useTranslation();
  const paymentMethods = data?.paymentMethods || data?.paymentMethod || [];

  const paymentMethodItems = paymentMethods.map(({ paymentProviderId, name, min, max }) => {
    const isValidAmount = +`${subtotalPrice}` >= +`${min}` && +`${subtotalPrice}` <= +`${max}`;

    return {
      id: paymentProviderId,
      text: name?.find((name) => name.lang === i18n.language)?.text,
      disabled: type !== COMPANY_FLOW_TYPE && type !== DRAWER_FLOW_TYPE && !isValidAmount
    };
  });

  return paymentMethodItems || [];
};

export default useGetPaymentMethodContent;

import classnames from "classnames";
import { BeatLoader } from "react-spinners";
import { TableOrderProps } from "types/components/TableOrder";
import TableOrderContent from "./components/TableOrderContent";
import styles from "./styles.module.scss";

const TableOrder = ({ isLoading, content, footerRows }: TableOrderProps) => {
  return (
    <>
      <div data-testid="table-order" className={styles.oderParamsItem}>
        <TableOrderContent content={content} />
        {footerRows?.length > 0 && (
          <div className="mt-3">
            {footerRows?.map(({ title, value, highlight }) => (
              <div
                className={classnames(styles.footerRow, { [styles.footerRowHighlighted]: highlight })}
                data-testid="table-order-footer-row"
              >
                <span>{title}</span>
                {isLoading ? (
                  <div className="d-flex align-items-center" data-testid="table-order-loader">
                    <BeatLoader color="#8d8d8d" />
                  </div>
                ) : (
                  <span>{value}</span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TableOrder;

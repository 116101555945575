import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

const StepperNavigationItem = ({ title, number, active, secondaryColor }) => {
  const renderText = (num) => {
    let upperText = "";
    let lowerText = "";
    const match = [...title.matchAll(/(.*)\/(.*)/gi)];

    if (match[0]) {
      upperText = match[0][1] || "";
      lowerText = match[0][2] || "";

      upperText = String(upperText).trim();
      lowerText = String(lowerText).trim();
    }

    switch (num) {
      case 1:
        return upperText;
      case 2:
        return lowerText;
      default:
        return upperText;
    }
  };

  return (
    <div
      data-testid="stepper-nav-item"
      className={classnames(styles.navigationItemWrapper, { [styles.navigationItemWrapperActive]: active })}
    >
      <div
        className={styles.navigationItemCircle}
        style={{ backgroundColor: active ? secondaryColor : undefined }}
      >
        <div className={styles.navigationItemNumber}>{number}</div>
      </div>

      <div className={styles.navigationItemText}>
        {renderText(1)} <br /> {renderText(2)}
      </div>
    </div>
  );
};

StepperNavigationItem.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
  active: PropTypes.bool
};

export default StepperNavigationItem;

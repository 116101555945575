import styles from "./styles.module.scss";

interface InstructionProps {
  imgUrl: string;
  // eslint-disable-next-line
  htmlContent: any;
}

const Instruction = ({ imgUrl, htmlContent }: InstructionProps) => {
  return (
    <div>
      <div className="m-divider-dashed-order mb-3"></div>
      <div className={styles.paymentInstructionBox} data-testid="instruction">
        <div data-testid="instruction-image" className={styles.paymentMethodItemImage}>
          <img src={imgUrl} alt="Logo" />
        </div>
        <span
          data-testid="instruction-text"
          className={styles.paymentInstructionText}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></span>
      </div>
    </div>
  );
};

export default Instruction;

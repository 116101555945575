const ReturnkeyValue = (input: Record<string, unknown>) => {
  const data: Array<Record<string, unknown>> = [];
  Object.entries(input).forEach(([key, value]) => {
    const result = {
      key: key,
      value: value
    };
    data.push(result);
  });
  return data;
};

export default ReturnkeyValue;

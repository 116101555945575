import { COMPANY_FLOW_TYPE, PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import useGetData from "hooks/api/useGetData";
import { useParams } from "react-router-dom";
import useGetQuote from "hooks/api/useGetQuote";
import { useAppContext } from "context/AppContext";

const useGetPrice = ({ productQuantityWatcher }: { productQuantityWatcher?: number } = {}): {
  totalPrice: number;
  subtotalPrice: number;
} => {
  let subtotalPrice = 0;
  const { type: flowType } = useParams();
  const { productData, prlData } = useGetData();
  const { quote } = useGetQuote();

  const { productQuantity, amount, isProduct } = useAppContext();

  const productArr = productData?.product || prlData?.product || [];
  switch (flowType) {
    case PRL_FLOW_TYPE:
      // For prl flow we are using quantity from the server response
      if (isProduct) {
        subtotalPrice = productArr?.reduce(
          (acc, curr) =>
            //   In order to get live-rendering for accordion total calculations, we need to use productQuantityWatcher
            Number(acc) +
            Number(curr.price?.value) * (productQuantityWatcher ? productQuantityWatcher : productQuantity),
          0
        );
      } else {
        subtotalPrice = productArr?.reduce(
          (acc, curr) => acc + Number(curr?.price?.value) * (Number(curr.quantity?.value) || 1),
          0
        );
      }
      break;
    case PRODUCT_FLOW_TYPE:
      // For product flow we are using quantity from the input element (accordion quantity input)
      subtotalPrice = productArr?.reduce(
        (acc, curr) =>
          //   In order to get live-rendering for accordion total calculations, we need to use productQuantityWatcher
          acc +
          Number(curr.price?.value) * (productQuantityWatcher ? productQuantityWatcher : productQuantity),
        0
      );
      break;
    case COMPANY_FLOW_TYPE:
      // For company flow type we just get the price entered by user.
      subtotalPrice = Number(amount);
      break;
  }

  const totalPrice = Number(quote) + Number(subtotalPrice);

  return { totalPrice, subtotalPrice };
};

export default useGetPrice;

import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NotFoundRoute = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.screenContainer}>
        <div className={styles.containerWrapper} data-testid="not-found-title">
          <div className={styles.errorBox}>
            <div className={styles.title}>{t("pageNotFound.title")}</div>
            <img className={styles.errorIcon} src={require("assets/error.webp")} alt="error-icon" />
          </div>
          <div className={styles.subTitle} data-testid="fr-subtitle">
            {t("pageNotFound.subtitleFr")}
          </div>
          <div className={styles.subTitleLight} data-testid="en-subtitle">
            {t("pageNotFound.subtitleEn")}
          </div>
          <div className={styles.errorMessage} data-testid="error-message"></div>
        </div>
      </div>
    </>
  );
};

NotFoundRoute.propTypes = {
  errorMessage: PropTypes.string
};

export default NotFoundRoute;

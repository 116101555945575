import mtnMoneyLogo from "assets/svg/mtn.svg";
import orangeMoneyLogo from "assets/svg/orange.svg";
import expressUnionLogo from "assets/svg/eu.svg";
import yoomeeMoneyLogo from "assets/svg/yoomee.svg";

export const YOUR_ORDER_ITEMS = [
  {
    title: 'Apple Macbook Pro 13"',
    price: 2000
  },
  {
    title: 'Apple Macbook Pro 14"',
    price: 2000
  },
  {
    title: 'Apple Macbook Pro 15"',
    price: 5000
  }
];

export const ACCORDION_ITEMS = [
  {
    cardContent: {
      title: 'Apple Macbook Pro 13"',
      quantity: 3,
      price: 2000
    },
    expansionContent: [
      {
        title: "Ref ID",
        content: "5352135"
      },
      {
        title: "Description",
        content:
          "Eu vitae quam arcu vivamus. Ante accumsan tellus et egestas quis vitae bibendum eu vitae quam arcu vivamusnte accumsan tellus et egestas."
      }
    ]
  },
  {
    cardContent: {
      title: "Xiaomi 11T",
      quantity: 1,
      price: 600
    },
    expansionContent: [
      {
        title: "Ref ID",
        content: "222255"
      },
      {
        title: "Description",
        content:
          "Eu vitae quam arcu vivamus. Xiaomi et egestas quis vitae bibendum eu vitae quam arcu vivamusnte accumsan tellus et egestas."
      }
    ]
  },
  {
    cardContent: {
      title: "Pentium 4",
      quantity: 1,
      price: 100
    },
    expansionContent: [
      {
        title: "Ref ID",
        content: "11111144"
      },
      {
        title: "Description",
        content: "Eu vitae quam arcu vivamus. Pentium 4 quam arcu vivamusnte accumsan tellus et egestas."
      }
    ]
  }
];

export const PAYMENT_METHOD_ITEMS = [
  { text: "Mobile Money", url: mtnMoneyLogo, id: "MTN" },
  { text: "Orange Money", url: orangeMoneyLogo, id: "orange" },
  { text: "Express Union", url: expressUnionLogo, id: "express" },
  { text: "Yoomee Money", url: yoomeeMoneyLogo, id: "yoomee" }
];

export const BUTTON_DROPDOWN_ITEMS = [
  { title: "FR", id: "fr" },
  { title: "EN", id: "en" }
];

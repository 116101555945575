import ArrowIcon from "assets/icons/ArrowIcon";
import classnames from "classnames";
import Attachment from "components/base/Attachment/Attachment";
import useGetData from "hooks/api/useGetData";
import useGetQuote from "hooks/api/useGetQuote";
import useGetAccordionContent from "hooks/content/useGetAccordionContent";
import useGetYourOrderContent from "hooks/content/useGetYourOrderContent";
import useGetPrice from "hooks/utils/useGetPrice";
import { MouseEvent, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { DocumentObject } from "types/api/api";
import formatCurrency from "utils/formatCurrency";
import ReturnkeyValue from "utils/returnKeyValue";
import styles from "./styles.module.scss";

interface ProductOrderProps {
  showHeader?: boolean;
  isLoading?: boolean;
  inputComponent?: JSX.Element;
  handleAttachmentClick?: (e: MouseEvent, file: DocumentObject) => void;
  expandable?: boolean;
  summary?: boolean;
}

const PrlOrder = ({
  showHeader= true,
  isLoading,
  inputComponent = <span />,
  handleAttachmentClick,
  expandable,
  summary
}: ProductOrderProps) => {
  const { subtotalPrice, totalPrice } = useGetPrice();
  const rows = useGetYourOrderContent();
  const { quote } = useGetQuote();
  const { t } = useTranslation();
  const [active, setActive] = useState(-1);
  const [expanded, setExpanded] = useState(false);
  const { themes } = useGetData();

  const items = useGetAccordionContent({
    inputComponent: inputComponent,
    productQuantityWatcher: 0,
    renderAttachmentComponent: (attachments: DocumentObject[]) => (
      <Attachment items={attachments} onClick={handleAttachmentClick ? handleAttachmentClick : () => true} />
    )
  });

  const headings = [
    {
      title: t("yourOrder.product"),
      xs: 3
    },
    {
      title: t("yourOrder.quantity"),
      xs: 2
    },
    {
      title: t("yourOrder.price"),
      xs: 3
    },
    {
      title: t("yourOrder.priceTotal"),
      xs: 4
    }
  ];

  const footerRows = [
    {
      title: t("yourOrder.subTotal"),
      value: formatCurrency(subtotalPrice)
    },
    {
      title: t("yourOrder.fees"),
      value: formatCurrency(quote || 0),
      highlight: true
    },
    {
      title: t("yourOrder.priceTotal"),
      value: formatCurrency(totalPrice)
    }
  ];

  const handleToggle = (index: number) => {
    setActive(active === index ? -1 : index);
    setExpanded(!expanded);
  };

  return (
    <>
      {showHeader && rows.map((product, key) => {
        return (
          <div key={key}>
            <div
              data-testid="order-container-card"
              className="d-flex justify-content-between p-2 rounded mt-1 mb-3"
              style={{ border: `1px solid ${themes.headerColor}` }}
            >
              <Col>
                {headings?.map((heading, index) => (
                  <Col
                    key={index}
                    xs={heading.xs}
                    className={`${index === headings.length - 1 && "text-bold"} mb-1 w-100`}
                    data-testid="table-order-content-heading-col"
                  >
                    <span className={styles.label}>{heading.title}</span>
                  </Col>
                ))}
              </Col>

              <Col className={`${styles.product_details_value}`}>
                <div className="d-flex flex-column align-items-end">
                  {ReturnkeyValue(product)?.map((row, index) => {
                    const value: string = row?.value as string;
                    return (
                      <Col key={index} data-testid="table-order-content-row">
                        {row?.key === "total" || row?.key === "price" ? (
                          <Col
                            xs={headings[index]?.xs}
                            className={`${index === headings.length - 1 && "text-bold"} w-100`}
                            data-testid="table-order-content-col"
                            key={value}
                          >
                            <span>{formatCurrency(+value)}</span>
                          </Col>
                        ) : (
                          <Col
                            xs={headings[index]?.xs}
                            className={`${index === headings.length - 1 && "text-bold"} w-100`}
                            data-testid="table-order-content-col"
                            key={value}
                          >
                            <span>{value}</span>
                          </Col>
                        )}
                      </Col>
                    );
                  })}
                </div>
                <div
                  className={classnames(styles.accordionItemWrapper, {
                    [styles.accordionItemWrapperExpanded]: active === key && expanded
                  })}
                >
                  {expandable && (
                    <div
                      className={styles.accordionItemArrowWrapper}
                      onClick={() => handleToggle(key)}
                      data-testid="accordion-item"
                    >
                      <ArrowIcon className={styles.accordionItemArrowIcon} />
                    </div>
                  )}
                </div>
              </Col>
            </div>

            {key === active && (
              <div
                data-testid="accordion-item"
                className={classnames(styles.accordionItemWrapper, {
                  [styles.accordionItemWrapperExpanded]: expanded
                })}
              >
                <div className={styles.accordionItemExpansion}>
                  {items[key]?.expansionContent.map(({ title, content }, index) => (
                    <div
                      className={styles.accordionItemExpansionContentWrapper}
                      key={`${title}_${index}`}
                      data-testid="accordion-expansion-item"
                    >
                      {title && <div className={styles.accordionItemExpansionTitle}>{String(title)}</div>}
                      <div className={styles.accordionItemExpansionContent}>{content}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}

      {/* <Accordion {...content} items={items} /> */}
      {footerRows?.length > 0 && summary && (
        <div className="mt-1">
          {footerRows?.map(({ title, value, highlight }, index) => (
            <div
              key={`${title}_${index}`}
              className={classnames(styles.footerRow, { [styles.footerRowHighlighted]: highlight })}
              data-testid="table-order-footer-row"
            >
              <span>{title}</span>
              {isLoading ? (
                <div className="d-flex align-items-center" data-testid="table-order-loader">
                  <BeatLoader color="#8d8d8d" />
                </div>
              ) : (
                <span>{value}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PrlOrder;

import React, { ReactNode, useEffect, useState } from "react";
import { UserContext, UserType } from "context/UserContext";

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(user));
  }, [user]);

  const clearData = () => {
    localStorage.clear();
    setUser(null);
  };
  /* eslint-disable */
  const updateUser = (data: any) => {
    const value = { ...user, ...data };
    setUser(value);
  };
  /* eslint-disable */
  const saveUserData = (key: string, data: any) => {
    const value = { ...user, ...data };
    localStorage.setItem(key, JSON.stringify(value));
  };

  const value = React.useMemo(
    () => ({
      user,
      clearData,
      updateUser,
      saveUserData
    }),
    [user]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

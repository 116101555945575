import { AccordionItemType, ExpansionContentType } from "types/components/Accordion";
import { PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import formatCurrency from "utils/formatCurrency";
import useGetData from "hooks/api/useGetData";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DocumentObject, LocalizedFieldObject } from "types/api/api";

const useGetAccordionContent = ({
  renderAttachmentComponent,
  productQuantityWatcher,
  inputComponent
}: {
  renderAttachmentComponent: (attachments: DocumentObject[]) => JSX.Element;
  productQuantityWatcher: number;
  inputComponent: JSX.Element;
}): AccordionItemType[] => {
  const { type: flowType } = useParams();
  const { productData, prlData } = useGetData();
  const productArr = productData?.product || prlData?.product || [];
  const { i18n, t } = useTranslation();
  const currency = prlData?.amount?.currency || productData?.amount?.currency;
  // eslint-disable-next-line
  return productArr?.map(({ name, description, quantity, reference, attachments, price }: any) => {
    const expansionContent: ExpansionContentType[] = [
      {
        title: reference?.label.find((lng: LocalizedFieldObject) => lng.lang === i18n.language)?.text,
        content: reference?.value
      },
      {
        title: description?.label.find((lng: LocalizedFieldObject) => lng.lang === i18n.language)?.text,
        content: description?.value
      }
    ];

    if (flowType === PRODUCT_FLOW_TYPE) {
      expansionContent.push({
        title: t("firstScreen.attachments"),
        content: renderAttachmentComponent(attachments)
      });
    }
    // For PRL flow display quantity
    //  For Product flow display input component
    const qty = flowType === PRL_FLOW_TYPE ? quantity?.value : inputComponent;
    const priceValue = price?.value;

    return {
      cardContent: {
        title: name?.value,
        quantity: qty,
        price: priceValue,
        formattedPrice: formatCurrency(
          //   For PRL flow use quantity?.value as quantity value
          // For Product flow use productQuantityWatcher as a quantity value (for live rendering)
          (flowType === PRL_FLOW_TYPE ? qty : productQuantityWatcher) * priceValue,
          currency
        )
      },

      expansionContent
    };
  });
};

export default useGetAccordionContent;

import { PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import useGetData from "hooks/api/useGetData";
import useGetQuote from "hooks/api/useGetQuote";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import createRegExpFromString from "utils/createRegExpFromString";
import Input from "./base/Input";
import styles from "./styles.module.scss";
import PrlOrder from "./ui/YourOrder/PrlOrder";
import ProductOrder from "./ui/YourOrder/ProductOrder";

const Stepper2: React.FC = ({ ...rest }) => {
  const { type: flowType } = useParams();
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const { data, isLoading } = useGetData();
  const { isLoading: isLoadingQuote } = useGetQuote();
  const { t, i18n } = useTranslation();

  return (
    <div {...rest} className={styles.secondScreenWrapper} data-testid="second-screen_wrapper">
      {flowType === PRODUCT_FLOW_TYPE && (
        <div className="mt-4">
          <div className={styles.oderWithFees}>
            {!isLoading && <ProductOrder isLoading={isLoadingQuote} />}
          </div>
          <div className="m-divider-dashed-order mb-3"></div>
        </div>
      )}

      {flowType === PRL_FLOW_TYPE && (
        <div className="mt-4">
          <div className={styles.oderWithFees}>
            {!isLoading && <PrlOrder isLoading={isLoadingQuote} summary />}
          </div>
          <div className="m-divider-dashed-order mb-3"></div>
        </div>
      )}

      <div className="d-flex align-items-center justify-content-center">
        <div className="m-container-full">
          <div className="m-h2 mt-4 mb-4" data-testid="heading-second">
            {t("secondScreen.heading2")}
          </div>
          <form>
            <div className={styles.customerInformationBlock}>
              {data?.customer.map((field) => {
                const pattern = createRegExpFromString(field?.regex || "");
                return (
                  <div className={styles.fieldWrapper} key={field?.name}>
                    <Input
                      id={field.name}
                      message={errors[field.name] && errors[field?.name].message}
                      key={field.name}
                      mandatory={field.mandatory}
                      error={!!errors[field.name]}
                      defaultValue={field.value}
                      label={field?.label?.find((l) => l?.lang === i18n.language)?.text || ""}
                      placeholder={field?.description?.find((l) => l?.lang === i18n.language)?.text || ""}
                      {...register(field.name, {
                        required: {
                          value: field.mandatory || false,
                          message: t("firstScreen.requiredInput")
                        },
                        pattern: {
                          value: pattern,
                          message: t("secondScreen.invalidCharacters")
                        }
                      })}
                    />
                  </div>
                );
              })}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Stepper2;

import { useQueryStatus } from "api/queries";
import LoadingScreen from "components/base/LoadingScreen";
import Header from "components/ui/Header";
import { PRL_FLOW_TYPE } from "constants/flowTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Footer from "shared/footer";
import { EnumPaymentStatus } from "types/api/api";
import ResultPage from "./components/ResultPage";
import styles from "./styles.module.scss";

const StatusPage = () => {
  const { type: flowType, orderId } = useParams();
  const [isFinalStatus, setIsFinalStatus] = useState(false);
  const { t } = useTranslation();

  const { isFetching, data, isLoading } = useQueryStatus(String(orderId), {
    enabled: !!(flowType === PRL_FLOW_TYPE && orderId) && !isFinalStatus,
    refetchInterval: 7000
  });

  /* eslint-disable */
  const isDone =
    !isFetching &&
    (data?.status === EnumPaymentStatus.SUCCESS ||
      data?.status === EnumPaymentStatus.ERROREDREFUNDED ||
      data?.status === EnumPaymentStatus.ERRORED);

  const isPending = data?.status === EnumPaymentStatus.PENDING;

  useEffect(() => {
    setIsFinalStatus(isDone);
    isDone && localStorage.clear();
  }, [isDone]);

  return (
    <>
      <Header />
      <div className={styles.screenContainer}>
        {/* Loading screen for payment */}
        {(isPending || isLoading) && (
          <LoadingScreen type="dark" title={t("loader.label")} isLoading={isPending || isLoading} />
        )}
        {!!data && <ResultPage data={data} isLoading={isLoading} />}
        <Footer />
      </div>
    </>
  );
};

export default StatusPage;

import { DRAWER_FLOW_TYPE, PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";
import { useParams } from "react-router-dom";
import { DocumentObject, EnumPaymentProvider, PaymentMethodObject } from "types/api/api";

// Inserted code
import classnames from "classnames";
import { appConfig } from "config/appConfig";
import errorMessage from "constants/errorMessage";
import { specialNames } from "constants/specialNames";
import { saveAs } from "file-saver";
import useGetData from "hooks/api/useGetData";
import useGetPaymentMethodContent from "hooks/content/useGetPaymentMethodContent";
import useUser from "hooks/useUser";
import useGetPrice from "hooks/utils/useGetPrice";
import React, { MouseEvent, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Controller, UseFormReturn, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AlertAmount from "routes/Home/screens/FirstScreen/components/AlertAmount";
import renderPaymentMethodInput from "routes/Home/screens/FirstScreen/utils/renderPaymentMethodInput";
import createRegExpFromString from "utils/createRegExpFromString";
import Attachment from "./base/Attachment";
import Input from "./base/Input";
import PaymentMethodPicker from "./base/PaymentMethodPicker";
import styles from "./styles.module.scss";
import PrlOrder from "./ui/YourOrder/PrlOrder";
import ProductOrder from "./ui/YourOrder/ProductOrder";

const Stepper1 = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  ...rest
}: {
  selectedPaymentMethod: PaymentMethodObject | null | undefined;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodObject | null | undefined>>;
}) => {
  const { type: flowType } = useParams();
  // eslint-disable-next-line
  type FormType = UseFormReturn<any> & { formState: { isValid: boolean; errors: any } };

  // --- GLOBAL RELATED CODE
  const { data, isLoading, drawerData, productData, prlData, themes } = useGetData();
  const { t, i18n } = useTranslation();
  const {
    control,
    register,
    clearErrors,
    setValue,
    watch,
    formState: { errors }
  }: FormType = useFormContext();

  const productQuantityWatcher = watch("productQuantity");
  const paymentMethodsContent = useGetPaymentMethodContent({ productQuantityWatcher });
  const { clearData } = useUser();

  const onSelectedPaymentMethod = (id: EnumPaymentProvider) => {
    // Reset field value
    setValue("paymentInput", "");
    // Clear errors
    clearErrors("paymentInput");

    if (data) {
      const payments = data?.paymentMethods || data?.paymentMethod;
      const paymentMethod = payments?.find((item) => item?.paymentProviderId === id);
      setSelectedPaymentMethod(paymentMethod);
    }
  };

  useEffect(() => {
    clearData();
  }, []);

  // Attachment handler
  const handleAttachmentClick = (e: MouseEvent, file: DocumentObject) => {
    e.preventDefault();
    let fileName = file?.documentLabel;
    // Manually add extension for .docx mimeType
    if (file?.mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      fileName = `${fileName}.docx`;
    }
    // Download
    saveAs(
      `${appConfig.ENVIRONMENT === specialNames.PRODUCTION ? origin : appConfig.API_URL}${file?.documentUrl}`,
      fileName
    );
  };

  //   -------------- PRODUCT AND CUSTOMER RELATED STUFF --------------
  const productDifferencechecker = productData?.quantity && productQuantityWatcher > productData?.quantity;
  const productAvailable = Number(productData?.quantity) === 0 ? false : true;
  const [qtyError, setQtyError] = useState<boolean>(false);
  const { subtotalPrice } = useGetPrice({ productQuantityWatcher });

  const QuantityInputHandler = () => {
    const handleOnchangeMinus = () => {
      if (productQuantityWatcher >= 2) {
        setValue("productQuantity", Number(productQuantityWatcher) - 1);
      }
    };

    const handleOnchangePlus = () => {
      if (
        productData?.quantity &&
        (Number(productQuantityWatcher) + 1 <= productData.quantity || Number(productData.quantity === -1))
      ) {
        setValue("productQuantity", Number(productQuantityWatcher) + 1);
      } else {
        setQtyError(true);
        setTimeout(() => {
          setQtyError(false);
        }, 4000);
      }
    };

    return (
      <div className={styles.quantity_editor} style={{ borderColor: themes.headerColor }}>
        <span className="minus" onClick={handleOnchangeMinus}>
          -
        </span>
        <Controller
          name="productQuantity"
          control={control}
          render={({ field }) => <input key="input_change" type="number" min={1} {...field} />}
        />
        <span className="plus" onClick={handleOnchangePlus}>
          +
        </span>
      </div>
    );
  };

  return (
    <>
      <div className={styles.firstScreenWrapper}>
        <div className={`${styles.firstHeader} m-h2 `} data-testid="heading-one">
          {t(flowType === PRODUCT_FLOW_TYPE ? "firstScreen.heading1" : "firstScreen.companyHeading1")}
        </div>

        {flowType === DRAWER_FLOW_TYPE &&
          drawerData?.firstScreenFields.map((field) => {
            return (
              <>
                {field && (
                  <div className={styles.fieldWrapper} key={field.name}>
                    <Input
                      id={field.name}
                      dataTestId={field.name}
                      message={errors[field.name] && errors[field.name].message}
                      mandatory={field.mandatory}
                      error={!!errors[field.name]}
                      defaultValue={field?.value}
                      label={field?.label?.find((l) => l.lang === i18n.language)?.text}
                      placeholder={field?.description?.find((l) => l.lang === i18n.language)?.text}
                      {...register(field.name, {
                        required: {
                          value: field?.mandatory || false,
                          message: errorMessage.REQUIRED
                        },
                        pattern: {
                          value: createRegExpFromString(field?.regex || ""),
                          message: errorMessage.PATTERN
                        }
                      })}
                    />
                  </div>
                )}
              </>
            );
          })}

        {/* PRODUCT FLOW TYPE COMPONENTS */}
        {flowType === PRODUCT_FLOW_TYPE && (
          <>
            <div className="mt-4">
              <AlertAmount
                paymentMethods={data?.paymentMethods || []}
                currency={prlData?.amount?.currency || productData?.amount?.currency}
                productBasketTotal={subtotalPrice}
              />
            </div>

            <div className="mt-4">
              {(qtyError || (productData?.quantity !== -1 && productDifferencechecker ? true : false)) && (
                <Alert variant="danger" data-testid="alert-amount">
                  {t("firstScreen.alertQuantityTooHigh", { quantity: productData?.quantity })}
                </Alert>
              )}
              {!productAvailable && (
                <Alert variant="danger" data-testid="alert-amount">
                  {t("firstScreen.alertQuantityTooHigh", { quantity: productData?.quantity })}
                </Alert>
              )}
            </div>

            {!isLoading && productAvailable && (
              <ProductOrder
                productQuantityWatcher={productQuantityWatcher}
                handleAttachmentClick={handleAttachmentClick}
                quantityInputHandler={QuantityInputHandler()}
                inputComponent={
                  <Controller
                    name="productQuantity"
                    control={control}
                    render={({ field }) => <input {...field} />}
                  />
                }
                isLoading={true}
              />
            )}
          </>
        )}

        {/* CUSTOMER FLOW TYPE COMPONENTS */}
        {flowType === PRL_FLOW_TYPE && (
          <>
            <div className="mt-4">
              <AlertAmount
                paymentMethods={data?.paymentMethods || []}
                currency={prlData?.amount?.currency || productData?.amount?.currency}
                productBasketTotal={subtotalPrice}
              />
            </div>

            <div className={styles.accordionWrapper}>
              {!isLoading && <PrlOrder handleAttachmentClick={handleAttachmentClick} expandable={true} />}
            </div>

            <div className="mt-4" data-testid="attachment-sector">
              <div className="m-h5">{t("firstScreen.attachments")}</div>
              <Attachment items={prlData?.attachments} onClick={handleAttachmentClick} />
            </div>
          </>
        )}

        {/* THIS IS FOR ALL FLOW TYPES */}
        <div className="d-flex align-items-center justify-content-center">
          <div className="m-container-full">
            <div className="m-h2 mt-4" data-testid="heading-two">
              {t("firstScreen.heading2")}
            </div>

            {/* Payment Method */}
            <div className={styles.paymentMethodContainer}>
              <Controller
                name="paymentMethod"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <PaymentMethodPicker
                    onSelect={onSelectedPaymentMethod}
                    items={paymentMethodsContent}
                    {...field}
                  />
                )}
              />
            </div>

            {/*  Phone number input */}
            <div
              data-testid="mobile_money_phone-wrapper"
              className={classnames(styles.mobileMoneyWrapper, {
                [styles.mobileMoneyWrapperVisible]: !!selectedPaymentMethod
              })}
            >
              {!isLoading &&
                renderPaymentMethodInput({
                  control,
                  errors,
                  i18n,
                  register,
                  selectedPaymentMethod
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepper1;

import { useQuery } from "@tanstack/react-query";
import { getQuote } from "api";
import { QuoteRequestObject } from "types/api/api";

// eslint-disable-next-line
const useQueryQuote = (payload: QuoteRequestObject, options?: any) => {
  return useQuery(["quote"], () => getQuote(payload), options);
};

export default useQueryQuote;

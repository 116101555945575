import PageStepper from "components/PageStepper";
import Stepper1 from "components/Stepper1";
import Stepper2 from "components/Stepper2.";
import LoadingScreen from "components/base/LoadingScreen/LoadingScreen";
import Header from "components/ui/Header";
import { COMPANY_FLOW_TYPE, DRAWER_FLOW_TYPE } from "constants/flowTypes";
import useGetData from "hooks/api/useGetData";
import useGetStatus from "hooks/api/useGetStatus";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Footer from "shared/footer";
import { PaymentMethodObject } from "types/api/api";
import styles from "./styles.module.scss";

const HomeRoute = () => {
  const { id = "123" } = useParams();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<PaymentMethodObject | null>();
  const { type: flowType } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyData, isLoading, isError } = useGetData();

  const {
    data: dataStatus,
    isDone: isDoneStatus,
    isPending: isPendingStatus,
    isError: isErrorStatus
  } = useGetStatus({ refetchInterval: 1500 });

  // Navigate user based on status
  useEffect(() => {
    if (isDoneStatus || isErrorStatus) {
      navigate(`/prl/${dataStatus?.orderId}/status/${id}`);
    }
  }, [id, navigate, isDoneStatus, flowType, isErrorStatus]);

  if (isError) {
    return <Navigate to={`/404`} />;
  }

  if (flowType === COMPANY_FLOW_TYPE && companyData) {
    navigate(`/${DRAWER_FLOW_TYPE}/${companyData.defaultDrawerUuid}`);
  }

  return (
    <>
      <Header />
      {/* Loading screen for first app load */}
      {isLoading && !isPendingStatus && <LoadingScreen type="light" isLoading />}
      {!isLoading && isPendingStatus && <LoadingScreen type="dark" isLoading />}

      <div className={styles.screenContainer}>
        <PageStepper activeStepIndex={activeStepIndex} setActiveStepIndex={setActiveStepIndex}>
          <Stepper1
            key={0}
            data-testid="first-screen-route"
            data-nav={t("homePage.companyStepperTitleFirstScreen")}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
          <Stepper2
            key={1}
            data-testid="first-screen-route"
            data-nav={t("homePage.companyStepperTitleSecondScreen")}
          />
        </PageStepper>

        <Footer />
      </div>
    </>
  );
};

export default HomeRoute;

import convertObjToArrayOfKeys from "api/utils/convertObjToArrayOfKeys";
import axios, { AxiosRequestHeaders, AxiosResponseHeaders } from "axios";
import { uniqueId } from "lodash";
import {
  CustomerLogResponseObject,
  DrawerResultObject,
  MerchantResultObject,
  MerchantResultObjectFormFields,
  MerchantResultObjectTransformed,
  OrderResultObject,
  OrderStatusObject,
  PrlObjectCreateCompanyPrlRequest,
  PrlObjectCreateCompanyPrlResponse,
  PrlObjectCreateProductPrlRequest,
  PrlObjectCreateProductPrlResponse,
  PrlObjectUpdateCompanyPrlRequest,
  PrlObjectUpdateRequest,
  PrlResultObject,
  PrlResultObjectTransformed,
  ProductResultObject,
  ProductResultObjectTransformed,
  QuoteRequestObject,
  QuoteResponseObject,
  ResponseFieldObject
} from "types/api/api";
import fetchHttpClient from "./utils/fetchHttpClient";

// Queries

export const getPrl = async (id: string): Promise<PrlResultObjectTransformed> => {
  const response = await fetch(`/api/prl/${id}`);
  const data: PrlResultObject = await response.json();

  const customerArr = convertObjToArrayOfKeys<CustomerLogResponseObject, ResponseFieldObject>(data?.customer);

  const transformedData = {
    ...data,
    customer: customerArr
  };

  return transformedData;
};

export const getProduct = async (id: string): Promise<ProductResultObjectTransformed> => {
  const response = await fetch(`/api/product/${id}`);
  const data: ProductResultObject = await response.json();

  const {
    nameFirst,
    nameLast,
    companyName,
    companyAddress,
    phonenumber,
    name,
    reference,
    description,
    attachments,
    price,
    ...rest
  } = data;

  const customerArr = convertObjToArrayOfKeys<CustomerLogResponseObject, ResponseFieldObject>({
    nameFirst,
    nameLast,
    companyName,
    companyAddress,
    phonenumber
  });

  // Transform product to match PRL response structure
  const transformedData = {
    product: [{ name, reference, description, attachments, price }],
    customer: customerArr,
    ...rest
  };

  return transformedData;
};

export const getCompany = async (id: string): Promise<MerchantResultObjectTransformed> => {
  const response = await fetch(`/api/merchant/${id}`);
  const data: MerchantResultObject = await response.json();

  const customerArr = convertObjToArrayOfKeys<MerchantResultObjectFormFields, ResponseFieldObject>(
    data?.formFields
  );
  const transformedData = {
    ...data,
    // Filter amount and reference fields out of customer array
    customer: customerArr.filter((field) => field.name !== "amount" && field.name !== "reference"),
    firstScreenFields: customerArr.filter((field) => field.name === "amount" || field.name === "reference")
  };

  return transformedData;
};

export const getDrawer = async (id: string): Promise<DrawerResultObject> => {
  const response = await fetch(`/api/drawer/${id}`);
  const data: DrawerResultObject = await response.json();

  const customerArr = convertObjToArrayOfKeys<MerchantResultObjectFormFields, ResponseFieldObject>(
    data?.formFields
  );
  const transformedData = {
    ...data,
    // Filter amount and reference fields out of customer array
    customer: customerArr.filter((field) => field.name !== "amount" && field.name !== "reference"),
    firstScreenFields: customerArr.filter((field) => field.name === "amount" || field.name === "reference")
  };

  return transformedData;
};

export const getStatus = async (id: string, headers?: AxiosRequestHeaders): Promise<OrderStatusObject> => {
  const response = await axios.get(`/api/order/${id}/status`, {
    headers: {
      ...headers,
      "x-correlation-id": uniqueId()
    }
  });

  return response.data;
};

export const downloadReceipt = async (ptn: string) => {
  return await fetchHttpClient(
    `/payments/${ptn}/proof`,
    {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-correlation-id": uniqueId()
      })
    },
    "blob"
  );
};

export const getQuote = async (payload: QuoteRequestObject): Promise<QuoteResponseObject> => {
  const response = await axios.post(`/api/quote`, payload);

  const data = response.data;
  return { ...data, quote: Math.round(Number(data?.quote)) };
};

// Mutations

export const postPay = async (id: string, payload: PrlObjectUpdateRequest): Promise<OrderResultObject> => {
  const response = await axios.post(`/api/prl/${id}/pay`, payload, {
    headers: {
      "x-request-id": uniqueId()
    }
  });

  return response.data;
};

export const postCreateProduct = async (
  payload: PrlObjectCreateProductPrlRequest
): Promise<PrlObjectCreateProductPrlResponse> => {
  const response = await axios.post(`/api/prl/product-prl`, payload, {
    headers: {
      "x-request-id": uniqueId()
    }
  });

  return response.data;
};

export const postCreateCompany = async (
  payload: PrlObjectCreateCompanyPrlRequest
): Promise<{ data: PrlObjectCreateCompanyPrlResponse; headers: AxiosResponseHeaders }> => {
  const response = await axios.post(`/api/prl/company-prl`, payload);

  return response;
};

export const putUpdateCompany = async (
  payload: PrlObjectUpdateCompanyPrlRequest,
  id: string,
  headers: AxiosRequestHeaders
): Promise<{ data: PrlResultObject; headers: AxiosResponseHeaders }> => {
  const response = await axios.put(`/api/prl/${id}`, payload, {
    headers: {
      ...headers,
      "x-correlation-id": uniqueId()
    }
  });

  return response;
};

export const postCreateDrawer = async (
  payload: PrlObjectCreateCompanyPrlRequest
): Promise<{ data: PrlObjectCreateCompanyPrlResponse; headers: AxiosResponseHeaders }> => {
  const response = await axios.post(`/api/prl/company-prl`, payload);

  return response;
};

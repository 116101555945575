import { forOwn } from "lodash";

const convertObjToArrayOfKeys = <T, B>(obj?: T): (B & { name: string })[] => {
  // eslint-disable-next-line
  const customerArr: any = [];

  if (obj) {
    forOwn(obj, (value, key) => {
      customerArr.push({ ...value, name: key });
    });
  }

  return customerArr;
};

export default convertObjToArrayOfKeys;

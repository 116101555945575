import classnames from "classnames/bind";
import PhoneInputNative from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import "./style.css";
import styles from "./styles.module.scss";
import { Control } from "react-hook-form";

const cx = classnames.bind(styles);

interface PhoneNumberInputProps {
  message?: string;
  id: string;
  rules: {
    [key: string]: {
      value: string | boolean | RegExp;
      message: string;
    };
  };
  control: Control;
  mandatory?: boolean;
  error?: boolean;
  disabled?: boolean;
  name: string;
  label?: string;
  // eslint-disable-next-line
  render?: any;
}

const PhoneInputRenderer = ({
  message,
  id,
  mandatory,
  error,
  disabled,
  label,
  render,
  ...rest
}: PhoneNumberInputProps) => {
  return (
    <div
      className={cx(styles.wrapper, { wrapperError: error, wrapperDisabled: disabled })}
      data-testid={"phone-input"}
    >
      {label && (
        <label htmlFor={id} className={styles.label}>
          {mandatory ? `${label} *` : label}
        </label>
      )}

      {render({ disabled, id, label, ...rest })}

      {message && (
        <span className={styles.message} data-testid="phone-input_message">
          {message}
        </span>
      )}
    </div>
  );
};

// Special type of input meant to be used together with react-hook-form Controller only
const PhoneInputControl = (props: PhoneNumberInputProps) => {
  return (
    <PhoneInputRenderer
      {...props}
      // eslint-disable-next-line
      render={(renderProps: any) => (
        <PhoneInputWithCountry
          defaultCountry="CM"
          countries={["CM"]}
          addInternationalOption={false}
          className={styles.input}
          {...renderProps}
        />
      )}
    ></PhoneInputRenderer>
  );
};

// Classic type of phone input, meant to be used without react-hook-form and for tests

// eslint-disable-next-line
const PhoneInput = (props: any) => (
  <PhoneInputRenderer
    {...props}
    // eslint-disable-next-line
    render={(renderProps: any) => <PhoneInputNative className={styles.input} {...renderProps} />}
  ></PhoneInputRenderer>
);

export { PhoneInput };
export default PhoneInputControl;

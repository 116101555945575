import { Col, Row } from "react-bootstrap";
import { TableOrderContentType } from "types/components/TableOrder";
import styles from "./styles.module.scss";

interface TableOrderContentProps {
  content: TableOrderContentType;
}

const TableOrderContent = ({ content }: TableOrderContentProps) => {
  const { rows, headings } = content;

  return (
    <div>
      <Row>
        {headings.map((heading) => (
          <Col xs={heading.xs} data-testid="table-order-content-heading-col">
            <span className={styles.label}>{heading.title}</span>
          </Col>
        ))}
      </Row>
      {rows.map((row) => {
        return (
          <Row className="mt-3" data-testid="table-order-content-row">
            {Object.values(row).map((val, index) => {
              return (
                <Col xs={headings[index]?.xs} data-testid="table-order-content-col">
                  <span>{val}</span>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};

export default TableOrderContent;

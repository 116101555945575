import { useMutation } from "@tanstack/react-query";
import { PrlObjectCreateCompanyPrlRequest } from "types/api/api";
import { postCreateCompany } from "api/index";

// eslint-disable-next-line
const useMutateCreateCompanyPrl = (options?: any) => {
  return useMutation((payload: PrlObjectCreateCompanyPrlRequest) => postCreateCompany(payload), options);
};

export default useMutateCreateCompanyPrl;

import { createContext, useContext, useEffect, useReducer } from "react";
import { EnumPaymentProvider } from "types/api/api";
import { isEmpty } from "lodash";

export type ContextType = {
  paymentInput: string;
  paymentMethod: EnumPaymentProvider;
  reference: string;
  amount: string;
  productQuantity: number;
  companyPrlUrl: string;
  companyUuId: string;
  xUpdateNonce: string;
  companyName: string;
  isProduct: boolean;
};

const defaultValues = {
  paymentInput: "",
  paymentMethod: EnumPaymentProvider.MOMO,
  reference: "",
  amount: "",
  productQuantity: 0,
  companyPrlUrl: "",
  companyUuId: "",
  xUpdateNonce: "",
  companyName: "",
  isProduct: false
};

// Get data from local storage
function getInitialState(): ContextType | [] {
  const localStorageData = localStorage.getItem(id);
  return localStorageData ? JSON.parse(localStorageData) : {};
}

// eslint-disable-next-line
const AppContext = createContext<ContextType>(defaultValues);
// eslint-disable-next-line
const AppDispatchContext = createContext<any>(null);

const id = window.location.pathname.split("/")[2];

// eslint-disable-next-line
export function AppProvider({ children, testData = {} }: { children: React.ReactNode; testData?: any }) {
  const [data, dispatch] = useReducer(appReducer, getInitialState());

  useEffect(() => {
    if (JSON.stringify(data) && id) {
      localStorage.setItem(id, JSON.stringify(data));
    }
  }, [data, id]);

  return (
    <AppContext.Provider value={!isEmpty(data) ? data : testData}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

export function useAppDispatch() {
  return useContext(AppDispatchContext);
}

// eslint-disable-next-line
function appReducer(data: any, action: any) {
  switch (action.type) {
    case "add": {
      return { ...data, ...action.payload };
    }

    default: {
      throw Error("error");
    }
  }
}

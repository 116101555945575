import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EnumPaymentProvider } from "types/api/api";
import { PaymentMethodPickerItemType } from "types/components/PaymentMethodPicker";
import PaymentMethodPickerItem from "./components/PaymentMethodPickerItem";
import styles from "./styles.module.scss";

interface PaymentMethodPickerProps {
  items?: PaymentMethodPickerItemType[];
  onChange: (id: EnumPaymentProvider) => void;
  value: EnumPaymentProvider | null;
  onSelect: (id: EnumPaymentProvider) => void;
}

const PaymentMethodPicker = ({ items, onChange, value, onSelect }: PaymentMethodPickerProps) => {
  const [activeItemId, setActiveItemId] = useState<EnumPaymentProvider | null>(value);

  useEffect(() => {
    if (activeItemId) {
      onChange(activeItemId);
      onSelect(activeItemId);
    }
    //   // eslint-disable-next-line
  }, [activeItemId]);

  return (
    <div className={styles.paymentMethodWrapper} data-testid="payment-method-picker">
      <Row className="h-100 gx-sm-4 gx-4 gy-1 gy-sm-2">
        {items?.map((item, index) => (
          <Col xs={3} key={index}>
            <PaymentMethodPickerItem
              key={index}
              {...item}
              active={activeItemId === item.id}
              setActiveItemId={setActiveItemId}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

PaymentMethodPicker.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onSelect: PropTypes.func
};

export default PaymentMethodPicker;

import { useEffect, useState } from "react";
import useGetData from "hooks/api/useGetData";
import { PaymentMethodObject } from "types/api/api";
import { useAppContext } from "context/AppContext";
import { useParams } from "react-router-dom";
import { PRL_FLOW_TYPE, PRODUCT_FLOW_TYPE } from "constants/flowTypes";

/**
 * Hook used to extract server data payment method based on payment method which user selected on the first screen
 */
const useGetSelectedPaymentMethodData = () => {
  const { data } = useGetData();
  const { type: flowType } = useParams();
  const appReducerPayment = useAppContext();
  const [paymentMethodData, setPaymentMethodData] = useState<PaymentMethodObject | null>(null);

  useEffect(() => {
    if (data) {
      const paymentMethod =
        flowType === PRODUCT_FLOW_TYPE || flowType === PRL_FLOW_TYPE
          ? data?.paymentMethods?.find((item) => item?.paymentProviderId === appReducerPayment?.paymentMethod)
          : data?.paymentMethod?.find((item) => item?.paymentProviderId === appReducerPayment?.paymentMethod);

      if (paymentMethod) {
        setPaymentMethodData(paymentMethod);
      }
    }
  }, [data, appReducerPayment?.paymentMethod]);

  return { data: paymentMethodData };
};

export default useGetSelectedPaymentMethodData;

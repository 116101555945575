import { createContext } from "react";
import { EnumPaymentProvider } from "types/api/api";

export interface UserType {
  accountNumber: string;
  paymentInput: string;
  paymentMethod: EnumPaymentProvider;
  reference: string;
  amount: string;
  productQuantity: number;
  companyPrlUrl: string;
  companyUuId: string;
  xUpdateNonce: string;
  quote?: number;
}

export interface UserContextInterface {
  user: UserType | null;
  clearData: () => void;
  /* eslint-disable */
  updateUser: (data: any) => void;
  saveUserData: (key: string, data: any) => void;
}

export const userContextDefaults: UserContextInterface = {
  user: {
    accountNumber: "",
    paymentInput: "",
    paymentMethod: EnumPaymentProvider.MOMO,
    reference: "",
    amount: "",
    productQuantity: 0,
    companyPrlUrl: "",
    companyUuId: "",
    xUpdateNonce: "",
    quote: 0
  },
  clearData: () => {
    //function to clear Data
  },
  updateUser: () => {
    //function to clear Data
  },
  saveUserData: () => {
    //function
  }
};

export const UserContext = createContext<UserContextInterface>(userContextDefaults);

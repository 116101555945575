import { UserContext } from "context/UserContext";
import { useContext } from "react";

function useUser() {
  const { user, clearData, updateUser, saveUserData } = useContext(UserContext);

  return {
    user,
    clearData,
    updateUser,
    saveUserData
  };
}

export default useUser;

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Helmet from "react-helmet";
import Layout from "./hoc/Layout";
import Routes from "./routes";
import { useEffect } from "react";
import { AppProvider } from "context/AppContext";
import { UserProvider } from "provider/UserProvider";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  // Fetch application meta data on component mount
  useEffect(() => {
    const fetchMeta = async () => {
      const response = await fetch("/api/meta");
      const data = await response.json();
      // eslint-disable-next-line
      console.log(`Version: ${data.version}`);
    };
    // eslint-disable-next-line
    fetchMeta();
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <Helmet>
        <title>PRL</title>
      </Helmet>
      <AppProvider>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <Layout>
              <Routes />
            </Layout>
          </QueryClientProvider>
        </UserProvider>
      </AppProvider>
    </>
  );
}

export default App;

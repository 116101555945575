import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import MemoSMobilePayLogo from "assets/icons/SmobilePayLogo";
import { PropagateLoader } from "react-spinners";
import PropTypes from "prop-types";

interface LoadingScreenProps {
  title?: string;
  isLoading?: boolean;
  type?: "dark" | "light";
  isPropagate?: boolean;
}

const LoadingScreen = ({ title, isLoading, type = "dark", isPropagate = true }: LoadingScreenProps) => {
  const isTypeLight = type === "light";

  return (
    <div
      style={{ height: "100%" }}
      data-testid={`loading-screen-${type}`}
      className={classnames(styles.loadingScreenWrapper, {
        [styles.loadingScreenWrapperActive]: isLoading,
        [styles.loadingScreenWrapperLight]: isTypeLight
      })}
    >
      <div className={styles.loadingScreenContent}>
        {isPropagate && (
          <MemoSMobilePayLogo style={{ marginBottom: !!title ? 0 : 15 }} data-testid="loading-screen-logo" />
        )}

        {title && (
          <div className={styles.loadingScreenText} data-testid="loading-screen-title">
            {title}
          </div>
        )}
        {isPropagate && <PropagateLoader data-testid="loading-screen-loader" size={20} color="white" />}
      </div>
    </div>
  );
};

LoadingScreen.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string
};

export default LoadingScreen;

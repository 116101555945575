import MemoArrowIcon from "assets/icons/ArrowIcon";
import classnames from "classnames";
import { useState } from "react";
import { ButtonDropdownItemType } from "types/components/ButtonDropdown";

import styles from "./styles.module.scss";

interface ButtonDropdownProps {
  items: ButtonDropdownItemType[];
  onSelect: (id: string) => void;
}

const ButtonDropdown = ({ items, onSelect }: ButtonDropdownProps) => {
  const [activeItem, setActiveItem] = useState(items[0]);
  const [open, setOpen] = useState(false);

  const switchActiveItem = (item: ButtonDropdownItemType) => {
    setActiveItem(item);
    setOpen(false);

    onSelect(item.id);
  };

  return (
    <div className={styles.buttonDropdownContainer} data-testid="button-dropdown">
      <div className={styles.buttonDropdownWrapper} role="button" onClick={() => setOpen(!open)}>
        <div className={styles.buttonDropdownText}>{activeItem.title}</div>
        <MemoArrowIcon className={styles.buttonDropdownArrow} />
      </div>
      <div
        className={classnames(styles.buttonDropdownExpansion, { [styles.buttonDropdownExpansionOpen]: open })}
      >
        {open && (
          <ul className="m-ul w-100">
            {items.map((item, index) =>
              // Do not display already selected value as a option
              item.title !== activeItem.title ? (
                <li
                  data-testid="button-dropdown_option"
                  key={index}
                  className={classnames(styles.buttonDropdownListOption)}
                  onClick={() => switchActiveItem(item)}
                >
                  {item.title}
                </li>
              ) : null
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ButtonDropdown;

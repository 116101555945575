import { Navigate, Route, BrowserRouter as Router, Routes as Switch } from "react-router-dom";
import PageNotFoundRoute from "routes/404/NotFoundRoute";
import HomeRoute from "./Home";
import StatusPage from "./Status";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/:type/:id" element={<HomeRoute />} />
        <Route path="/:type/:orderId/status/:id" element={<StatusPage />} />
        <Route path="/404" element={<PageNotFoundRoute />} />
        <Route path="*" element={<Navigate to={`/404`} />} />
      </Switch>
    </Router>
  );
};

export default Routes;
